<template>
    <div class="status_bar">
        <div class="title_section">

            <div v-if="showPrevious" class="previous" @click="previousClicked">
                <div class="icon">
                    <IconSvg class="icon" width="25" :name="'chevron-left'" />
                </div>
            </div>

            <h1 class="title title--h2">
                {{ currentTitle }}
            </h1>
            <div v-if="globalStock !== null && showQuota" class="current_stock">
                <div class="icon">
                    <IconSvg class="icon" width="25" :name="'ticket'" />
                </div>
                <div class="status">
                    <span>{{ globalStock }}</span>
                </div>
            </div>

            <div v-if="isTpeVisible" @click="reconnectReader" class="tpe_status" :class="{active: tpeStore.reader, inactive: tpeStore.disconnectedReader}">
                <div class="icon">
                    <IconSvg class="icon" width="25" :name="'terminal'" />
                </div>
                <div class="status">
                    <span v-if="tpeStore.reader">{{ tpeStore.reader.label }}</span>
                    <span v-else-if="tpeStore.disconnectedReader">{{ tpeStore.disconnectedReader.label }}</span>
                </div>
            </div>

        </div>

        <div v-if="showMenu" class="steps">
            <p v-if="isProduct" :class="{active: activeStep == 'buvette'}"><span class="title title--h3">{{ $t('statusBar.products') }}</span></p>
            <p v-else :class="{active: activeStep == 'ticket'}"><span class="title title--h3">{{ $t('statusBar.prices') }}</span></p>
            <p :class="{active: activeStep == 'infos'}"><span class="title title--h3">{{ $t('statusBar.infos') }}</span></p>
            <p :class="{active: activeStep == 'payment'}"><span class="title title--h3">{{ $t('statusBar.payment') }}</span></p>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useEventStore } from "@/stores/EventStore"
    import { computed } from "vue"
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { useRoute } from "vue-router"
    import { useTpeStore } from "@/stores/TpeStore"
    import { useAppStore } from "@/stores/AppStore"

    const tpeStore = useTpeStore()
    const eventStore = useEventStore()
    const route = useRoute()
    const appStore = useAppStore()
    const props = defineProps({
        title: {type: String, required: false},
        showQuota: {type: Boolean, default: true},
        showMenu: {type: Boolean, default: true},
        showTpe: {type: Boolean, default: true},
        isProduct: {type: Boolean, default: false},
        showPrevious: {type: Boolean, default: false}
    })

    const isTpeVisible = computed(() => {
        return (props.showTpe && eventStore.currentEvent.ticketOffice.paymentMethods.includes('creditCardTerminal'))
    })

    const emit = defineEmits(['previousClicked'])

    const currentTitle = computed(() => {
        if (!props.title && eventStore.currentEvent) {
            return eventStore.currentEvent.name
        }
        return props.title
    })

    const globalStock = computed(() => {
        if (eventStore.currentSession) {
            if (eventStore.currentSession.quota != null) {
                return eventStore.currentSession?._app?.currentStock || null
            }
        }
        else if(eventStore.currentEvent) {
            if (eventStore.currentEvent.ticketing.quota != null) {
                return eventStore.currentEvent?._app?.currentStock || null
            }
        }
        return null
    })

    const activeStep = computed(():'ticket'|'infos'|'payment'|'buvette' => {
        switch (route.name) {
            case 'buvette':
                return 'buvette'
            case 'ticket':
                return 'ticket'
            case 'buvette_infos':
                return 'infos'
            case 'infos':
                return 'infos'
            case 'payment':
                return 'payment'
        }
    })

    const reconnectReader = (async () => {
        if (tpeStore.disconnectReader) {
            appStore.isLoading = true
            await tpeStore.reconnectReader()
            appStore.isLoading = false
        }
    })

    const previousClicked = (() => {
        emit('previousClicked')
    })

</script>