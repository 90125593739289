<template>
    <button
        :type="type"
        :disabled="loading || disabled"
        class="ob-btn"
        :class="btnClass"
    >
        <div class="spinner" style="width:50px;" v-if="loading">
            <ObSpinner />
        </div>
        <div class="button-inner">
            <span v-if="icon" class="icon">
                <IconSvg width="25" :name="icon" />
            </span>
            <div>
                <slot></slot>
            </div>
        </div>
    </button>
</template>

<script setup lang="ts">
    import ObSpinner from '@/components/atoms/Spinner.vue'
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { computed } from 'vue'

    interface Props {
        loading?: boolean,
        type?: 'button' | 'submit' | 'reset',
        disabled?: boolean,
        icon?: string,
        size?: 'default' | 'big',
        variant?: 'primary' | 'secondary',
        fullWidth?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        loading: false,
        type: 'submit',
        disabled: false,
        size: 'default',
        icon: null,
        variant: 'primary',
        fullWidth: true
    })

    const btnClass = computed(() => {
        const classes: any = {
            'is-loading': props.loading,
            disabled: props.disabled,
            'with-icon': (props.icon),
            'full-width': (props.fullWidth),
        }
        classes[`variant-${props.variant}`] = true
        classes[`size-${props.size}`] = true

        return classes
    })

</script>
