<template>
    <div class="page-default page--stats">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Statistiques" :showQuota="false" :showMenu="false" :showTpe="false" />
            <div class="content_inner">
                <div class="searchForm">
                    <FormKit
                        type="select"
                        name="sessionId"
                        placeholder="Choisissez une session de vente"
                        v-model="sessionId"
                        @change="updateSession"
                        :value="'all'"
                        :options="sessionsList"
                    />
                </div>
                <div class="main_infos">
                    <h3 class="title title--h3">Informations sur la session</h3>
                    <MTable
                        :selectable="false"
                        :show-labels="false"
                        :rows="sessionRows"
                        :labels="sessionLabels"
                    />
                </div>

                <div class="synthese">
                    <h3 class="title title--h3">Synthèse des opérations</h3>
                    <MTable
                        class="table_ticket"
                        :selectable="false"
                        :show-labels="true"
                        :rows="syntheseTicketsRows"
                        :labels="syntheseTicketsLabel"
                    />
                </div>

                <div class="recettes">
                    <h3 class="title title--h3">Recettes</h3>
                    <MTable
                        :selectable="false"
                        :show-labels="true"
                        :rows="recetteRows"
                        :labels="recetteLabels"
                    />
                </div>
                <div v-if="totalCaisse" class="solde">
                    <div class="details">Solde de caisse: <span>{{ totalCaisse }}</span></div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import MTable from '@/components/molecules/Table.vue'

    import { SellingSession } from '@/interfaces/SellingSession';
    import { useSellingSessionStore } from '@/stores/SellingSessionStore';
    import { useUserStore } from '@/stores/UserStore';
    import { onMounted, ref, computed, watch } from 'vue';
    import { AppPrice } from '@/tools/Price';
    import { PaymentMethodEnum, PaymentMethodTypeEnum } from '@/interfaces/Order';

    const userStore = useUserStore()
    const sellingSessionStore = useSellingSessionStore()

    const sessionId = ref(null)
    const operatorId = ref(null)

    onMounted(async () => {
        await sellingSessionStore.fetchAll()
        await fetchStats()
    })

    const fetchStats = (async () => {
        await sellingSessionStore.fetchStats(sessionId.value)
    })

    watch(sessionId, async (oldValue, newValue) => {
        await fetchStats()
    })

    const selectedSession = computed<SellingSession|null>(() => {
        if (sessionId.value) {
            return sellingSessionStore.sellingSessions.find(sellingSession => sellingSession.id === sessionId.value)
        }
        if (sellingSessionStore.sellingSessions.length) {
            return sellingSessionStore.sellingSessions.find(sellingSession => sellingSession.id === userStore.sellingSession.id)
        }
        if ((operatorId.value && operatorId.value != userStore.me.id) && !sessionId.value) {
            return sellingSessionStore.sellingSessions.find(sellingSession => sellingSession.authorId === operatorId.value)
        }
        return null
    })

    const updateSession = (async () => {
        //await fetchData()
    })

    const updateOperator = (async () => {
        //await fetchData()
    })

    const sessionLabels = computed(() => {
        return [
            {
                label: "Label",
                value: "label",
                align: "left"
            },
            {
                label: "Value",
                value: "value",
                align: "right"
            },
        ]
    })

    const sessionRows = computed(() => {
        if (selectedSession.value) {
            return [
                { label: 'Opérateur', value: `${selectedSession.value.author.firstName} ${selectedSession.value.author.lastName}`},
                { label: 'Numéro', value: `${selectedSession.value.sessionNumber}`},
                { label: 'Ouverte le', value: `${formatDate(selectedSession.value.createdAt)}`},
                { label: 'Fermée le', value: (selectedSession.value.closedAt) ? formatDate(selectedSession.value.closedAt) : 'En cours...'}
            ]
        }
    })

    const syntheseTicketsLabel = computed(() => {
        return [
            { label: "Type de commande", value: "type", align: "left"},
            { label: "Commandes", value: "orders", align: "left"},
            { label: "Unités (Billets/Produits)", value: "units", align: "left"},
            { label: "Montant total", value: "price", align: "left"},
        ]
    })
    const syntheseTicketsRows = computed(() => {
        if (sellingSessionStore.stats) {
            return [
            {
                    type: 'Billetterie',
                    orders: sellingSessionStore.stats.ordersTicketingCount,
                    units: sellingSessionStore.stats.ticketsCount,
                    price: new AppPrice(sellingSessionStore.stats.ticketsTotalPrice).divide100(),
                },
                {
                    type: 'Buvette',
                    orders: sellingSessionStore.stats.ordersBuvetteCount,
                    units: sellingSessionStore.stats.productsCount,
                    price: new AppPrice(sellingSessionStore.stats.productsTotalPrice).divide100(),
                }
            ]
        }
    })

    const totalCaisse = computed(() => {
        if (sellingSessionStore.stats) {
            return new AppPrice(sellingSessionStore.stats.totalPrice).divide100()
        }
        return null
    })

    const recetteLabels = computed(() => {
        return [
            { label: "Type de paiement", value: "type", align: "left"},
            { label: "Montant total", value: "price", align: "left"},
        ]
    })

    const recetteRows = computed(() => {
        const rows = []
        if (sellingSessionStore.stats) {
            sellingSessionStore.stats.paymentMethods.forEach(paymentMethod => {
                if (paymentMethod._id !== PaymentMethodTypeEnum.custom && paymentMethod._id !== PaymentMethodTypeEnum.free) {
                    const type = (PaymentMethodEnum[paymentMethod._id]) ? PaymentMethodEnum[paymentMethod._id] : paymentMethod._id
                    rows.push({
                        type,
                        total: new AppPrice(paymentMethod.total).divide100()
                    })
                }
            })
            sellingSessionStore.stats.paymentCustomMethods.forEach(paymentCustomMethod => {
                const type = (paymentCustomMethod._id?.label) ? paymentCustomMethod._id?.label : '- Inconnue -'
                rows.push({
                    type,
                    total: new AppPrice(paymentCustomMethod.total).divide100()
                })
            })
        }
        return rows
    })
    /**
     * @TODO : Refacto la partie date.
     */
     const formatDate = ((dateTime) => {
        let dateString = ''
        const date = new Date(dateTime)
        dateString += date.toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        dateString += ' à '
        dateString += date.toLocaleTimeString('fr', {
            hour: '2-digit',
            minute: '2-digit'
        })
        return dateString
    })

    const sessionsList = computed(() => {
        const sellingSessions = sellingSessionStore.sellingSessions.map(sellingSession => {
            let label = `${sellingSession.author.firstName} ${sellingSession.author.lastName} - ${formatDate(sellingSession.createdAt)}`
            if (sellingSession.status == 'opened') {
                label += ' (en cours)'
            }
            return {
                label,
                value: sellingSession.id
            }
        })
        return [
            {
                label: "Toutes les sessions",
                value: null
            },
            ...sellingSessions
        ]
    })

    const operatorsList = computed(() => {
        const operators = [
            {
                label: "Tous les opérateurs",
                value: null
            },
        ]
        sellingSessionStore.sellingSessions.forEach(sellingSession => {
            if (sellingSession.authorId && sellingSession.author) {
                const foundOperator = operators.find(operator => operator.value === sellingSession.authorId)
                if (!foundOperator) {
                    operators.push({
                        value: sellingSession.authorId,
                        label: `${sellingSession.author.firstName} ${sellingSession.author.lastName}`
                    })
                }
            }
        })

        return operators
    })

</script>