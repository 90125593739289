<template>
  <main ref="root" id="appRoot" class="fullscreen-wrapper" :class="{isLoading: appStore.isLoading}">
    <RouterView />
    <ModalsContainer />
    <TpeCollector />
    <div v-if="appStore.isLoading" class="app-loading">
      <IconSvg class="loader" :name="'loader'" />
    </div>
    <ModalConfirm :modal-id="appStore.pauseUserSessionModalId" title="Suspendre la session" @confirm="() => confirmPauseUserSession()">
        <div class="details">
            <p>L'activité en cours sera suspendue.</p>
            <p>Retrouvez votre session en vous connectant à nouveau</p>
        </div>
    </ModalConfirm>

    <ModalConfirm :modal-id="appStore.closeUserSessionModalId" title="Terminer la session" @confirm="() => confirmCloseUserSession()">
        <div class="details">
            <p>En terminant la session, vous clôturez également votre caisse.</p>
        </div>
    </ModalConfirm>
  </main>
</template>

<script setup lang="ts">
  import { ModalsContainer, useVfm } from 'vue-final-modal'
  import { useAppStore } from '@/stores/AppStore'
  import { useUserStore } from '@/stores/UserStore'
  import ModalConfirm from '@/components/molecules/modal/ModalConfirm.vue'
  import IconSvg from '@/components/atoms/ASvg.vue'
  import TpeCollector from '@/components/organisms/TpeCollector.vue'
  import { useRouter } from 'vue-router'

  const vfm = useVfm()
  const router = useRouter()

  const appStore = useAppStore()
  const userStore = useUserStore()

  const confirmPauseUserSession = (async () => {
        userStore.logOut()
        vfm.close(appStore.pauseUserSessionModalId)
        router.push('login')
    })

    const confirmCloseUserSession = (async () => {
        const result = await userStore.closeSession()
        vfm.close(appStore.closeUserSessionModalId)
        if (result) {
            router.push('login')
        }
    })
</script>

<style scoped>

</style>
