<template>
    <div class="page-guichet page-guichet--product">
        <SidebarVue />
        <div class="content">
            <StatusBar :showQuota="false" :is-product="true" />
            <div class="content_inner">
                <div class="content_inner_inner">

                    <div class="search">
                        <FormKit
                            type="text"
                            name="mail"
                            v-model="searchString"
                            placeholder="Rechercher un produit"
                        />
                        <div class="clear_text">
                            <IconSvg v-if="searchString" @click="searchString = null" width="25" name="close" />
                        </div>
                    </div>
                    <div class="categories" v-if="ticketTypeStore.ticketsPerCategory">
                        <ProductCategoryVue
                            @click="toggleCategory"
                            v-for="(category, indexCat) in ticketTypeStore.ticketsPerCategory"
                            :key="indexCat"
                            :isActive="(category.category && category.category.id == activeCategory)"
                            :category="category.category"
                        />
                    </div>
                    <div class="products" v-if="filteredProducts.length">
                        <CardProductVue
                            v-for="(ticketType, indexTt) in filteredProducts"
                            :key="indexTt"
                            :ticketType="ticketType"
                            :category="ticketType.ticketTypeCategory"
                        />
                    </div>
                    <div v-else>
                        {{ $t('products.empty') }}
                    </div>
                </div>
                <Cart :isProduct="true" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useTicketTypeStore } from '@/stores/TicketTypeStore'
    import CardProductVue from '@/components/molecules/CardProduct.vue'
    import ProductCategoryVue from '@/components/molecules/ProductCategory.vue'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import IconSvg from '@/components/atoms/ASvg.vue'

    import { onMounted } from 'vue'
    import { useCartStore } from '@/stores/CartStore'
    import { computed } from 'vue'
    import { ref, watch } from 'vue'
    import { TicketType } from '@/interfaces/TicketType'
    import shoetest from 'shoetest'
    import { useOrderStore } from '@/stores/OrderStore'
    import { useEventStore } from '@/stores/EventStore'
    import { useUserStore } from '@/stores/UserStore'
import { useAppStore } from '@/stores/AppStore'

    const ticketTypeStore = useTicketTypeStore()
    const cartStore = useCartStore()
    const orderStore = useOrderStore()
    const activeCategory = ref<string>(null)
    const searchString = ref<string>(null)
    const eventStore = useEventStore()
    const userStore = useUserStore()
    const appStore = useAppStore()

    onMounted(async () => {
        await cartStore.cancel()
        cartStore.isProduct = true
        const eventId = userStore.sellingSession?.eventId
        await eventStore.retrieveEvent(eventId)
        await ticketTypeStore.fetchAll(true)
    })

    const toggleCategory = ((event) => {
        if (activeCategory.value) {
            if (event.category.id === activeCategory.value) {
                activeCategory.value = null
                return true
            }
        }
        activeCategory.value = event.category.id
        return true
    })

    const filteredProducts = computed(() => {
        return ticketTypeStore.ticketsWithCategory.filter((ticketType: TicketType) => {
            if (!activeCategory.value || (activeCategory.value && activeCategory.value === ticketType.ticketTypeCategoryId)) {
                if (searchString.value && searchString.value.trim()) {
                    const searchCat = (ticketType.ticketTypeCategory && shoetest.test(searchString.value.trim(), ticketType.ticketTypeCategory.name))
                    return (shoetest.test(searchString.value.trim(), ticketType.name) || searchCat)
                }
                return true
            }
            return false
        })
    })

    watch(searchString, async (newString, oldString) => {
        if (newString) {
            activeCategory.value = null
        }
    })

</script>