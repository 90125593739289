import { createSSRApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import VueFullscreen from 'vue-fullscreen'
import { i18n } from './i18n/index'
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify';
import * as Sentry from "@sentry/vue";
import { plugin, defaultConfig } from '@formkit/vue'
import { fr } from '@formkit/i18n'
import { icons } from '@formkit/icons'
import FormKitToggle from '@/components/atoms/formkit/toggle.vue'

import 'vue3-toastify/dist/index.css';
import { createVfm } from 'vue-final-modal'

import './bootstrap';
import 'vue-final-modal/style.css'
import './assets/scss/styles.scss';
const vfm = createVfm()
const pinia = createPinia()
pinia.use(({store}) => { store.$router = markRaw(router) })
import App from './App.vue'
const app = createSSRApp(App)
    .use(i18n)
    .use(vfm)
    .use(
        plugin,
        defaultConfig({
            icons: {
                ...icons,
            },
            locales: { fr },
            locale: 'fr',
            inputs: {
                toggleswitch: {
                    type: 'input',
                    family: 'checkbox',
                    component: FormKitToggle
                },
            },
        })
    )
    .use(pinia)
    .use(Vue3Toasity, {
        autoClose: 3000,
        position: 'top-right'
    } as ToastContainerOptions,)
    .use(VueFullscreen)


Sentry.init({
    app,
    environment: import.meta.env.VITE_API_BASE_URL,
    dsn: "https://c0d7c3dce38d45328ea1d1c6aeba68a2@o4504634938032128.ingest.sentry.io/4504909794181120",
    integrations: [
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
});

app.use(router)
router.isReady().then(() => {
    app.mount('#app');
});