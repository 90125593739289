<template>
    <div @click="cardClicked(event)" class="card card__event">
        <div class="top">
            <h3 class="card--title">{{ event.name }}</h3>
        </div>
        <div class="bottom">
            <div class="dates" :class="{'with-end-date': (dateEnd)}">
                <div v-if="dateStart" class="start_date date">
                    <span class="day">{{ dateStart.date }}</span>
                    <span class="hour">{{ dateStart.hour }}</span>
                </div>
                <div v-if="dateEnd" class="end_date date">
                    <span class="day">{{ dateEnd.date }}</span>
                    <span class="hour">{{ dateEnd.hour }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { computed } from "vue";
    const emit = defineEmits(['clicked'])
    const props = defineProps({
        event: {type: Object, required: true},
    })

    const dateStart = computed(() => {
        if (props.event.dates.startDate) {
            const date = new Date(props.event.dates.startDate)
            return {
                'date': date.toLocaleDateString('fr', { day: "numeric", month: "short", year: "numeric" }),
                'hour': date.toLocaleTimeString('fr', {hour:"numeric", "minute":"numeric"})
            }
        }
        return null
    })

    const dateEnd = computed(() => {
        if (props.event.dates.endDate) {
            const date = new Date(props.event.dates.endDate)
            return {
                'date': date.toLocaleDateString('fr', { day: "numeric", month: "short", year: "numeric" }),
                'hour': date.toLocaleTimeString('fr', {hour:"numeric", "minute":"numeric"})
            }
        }
        return null
    })

    const cardClicked = (event) => {
        emit('clicked', event);
    }
</script>