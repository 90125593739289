<template>
    <div class="user_session">
        <template v-if="logout === true">
            <div class="menu-item" @click="userLogout()">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'close'" />
                </div>
                <div class="label">{{ $t('global.menu.logout') }}</div>
            </div>
        </template>
        <template v-else>
            <div class="menu-item" @click="vfm.open(appStore.pauseUserSessionModalId)">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'pause'" />
                </div>
                <div class="label">{{ $t('global.menu.pause') }}</div>
            </div>
            <div class="menu-item" @click="vfm.open(appStore.closeUserSessionModalId)">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'close'" />
                </div>
                <div class="label">{{ $t('global.menu.close') }}</div>
            </div>
        </template>
    </div>
</template>
<script setup lang="ts">
    import { useAppStore } from "@/stores/AppStore";
    import { useVfm } from "vue-final-modal";
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { useUserStore } from "@/stores/UserStore";
    import { useRouter } from "vue-router";
    const appStore = useAppStore()
    const vfm = useVfm()
    const userStore = useUserStore()
    const router = useRouter()

    const props = defineProps({
        logout: {type: Boolean, default: false},
    })

    const userLogout = (async () => {
        const result = await userStore.closeSession()
        vfm.close(appStore.closeUserSessionModalId)
        if (result) {
            router.push('login')
        }
    })

</script>