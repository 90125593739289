import { SellingSession } from "./SellingSession"

export interface Order {
    id: string
    totalPrice?: {
        commission?: {
            flat: number
            percentage: number
            calculated: number
        }
        currency?: {
            isoCode: string
            value: any
        }
        promoCodeId?: string
        sellingPrice: number
        costPrice: number
        taxId: string
    }
    payment?: {
        method: PaymentMethodTypeEnum
        customMethod?: {
            value: string
            label: string
        } | null
    }
    sendingMethod?: string
    origin: OrderOriginTypeEnum
    isProduct?: boolean
    token: string
    amount?: number
    bookingTime?: number
    orderNumber?: string
    code?: number
    ticketOfficeSessionId?: string
    commissionIncreased?: boolean
    invoiceData?: OrderInvoiceData
    expire?: Date
    tickets?: number
    promoCode?: string
    status?: OrderStatusTypeEnum
    promoCodeValid?: boolean
    ticketOfficeSession?: SellingSession
    createdAt?: Date
    _app?: {
        paymentMethods?: paymentMethod[]
    }
}

export enum OrderStatusTypeEnum {
    DRAFT = 'draft',
    PAID = 'paid',
    NOT_PAID = 'not_paid',
    PARTIALLY_PAID = 'partiallyPaid',
    CANCELED = 'canceled',
    INVITATION = 'invitation',
    REFUNDED = 'refunded',
    ABANDONED = 'abandoned'
}

export enum OrderStatusEnum {
    draft = 'Brouillon',
    paid = 'Payée',
    not_paid = 'Non-payée',
    partiallyPaid = 'Partiellement payée',
    canceled = 'Annulée',
    invitation = 'Invitation',
    refunded = 'Remboursée',
    abandoned = 'Abandonnée'
}

export enum PaymentMethodTypeEnum {
    creditCard = 'creditCard',
    creditCardTerminal = 'creditCardTerminal',
    creditCardExternal = 'creditCardExternal',
    cash = "cash",
    check = "check",
    custom = "custom",
    free = 'free'
}

export enum PaymentMethodEnum {
    creditCard = 'Carte bancaire (Web)',
    creditCardTerminal = 'Carte bancaire (TPE)',
    creditCardExternal = 'Carte bancaire (Externe)',
    cash = "Espèce",
    check = "Chèque",
    free = 'Aucun (gratuit)'
}

export enum OrderOriginTypeEnum {
    TICKET_OFFICE = 'ticketOffice',
    WEB = 'web',
    STOCK_IMPRESSION = 'stockImpression',
    INVITATION = 'invitation'
}

export enum OrderOriginEnum {
    ticketOffice = 'Guichet',
    web = 'Web',
    stockImpression = 'Impression de stock',
    invitation = 'Invitation'
}

type paymentMethod = {
    name: string
    amount: number
}

export interface OrderInvoiceData {
    civility?: string
    firstName?: string
    lastName?: string
    email?: string
    zone?: string
    additionalInfos?: string
    numTva?:string
    company?:string
    address?:string
}