import { defineStore, Store } from "pinia";
import { Event } from "@/interfaces/Event";
import { DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import { getAllItemsAction, getItemAction } from "./common/actions";
import { CartState } from "./CartStore";
import { CartItem } from "@/interfaces/CartItem";
import { EventSession } from "@/interfaces/EventSession";
import * as Sentry from "@sentry/browser";

export interface EventState {
    currentEvent: Event | null,
    events: Event[] | null,
    sessions: EventSession[] | null,
    currentSession: EventSession | null
}

export const useEventStore = defineStore("EventStore", {
    state: (): EventState => {
        return {
            currentEvent: null,
            events: [],
            sessions: [],
            currentSession: null
        }
    },
    actions: {
        async retrieveEvent(eventId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'dates', 'ticketing', 'ticketOffice', 'status', 'useRoomPlacement', 'commission', 'commissionIncreased']
            params.populate = ['organisation']
            await getItemAction<Event, Store<'EventStore'>>(`/events/${eventId}`, params, this, 'currentEvent')
            if (this.currentEvent) {
                this.currentEvent['_app'] = {
                    bufferStock: 0,
                    currentStock: 0
                }
            }
        },
        async listPublishedEvents(organisationId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'organisationId', 'dates', 'ticketing', 'ticketOffice', 'status', 'useRoomPlacement', 'commission', 'commissionIncreased']
            params.populate = ['organisation']
            params.filter = { organisationId, status: 'published' }
            await getAllItemsAction<Event, Store<'EventStore'>>('/events/list', params, this, 'events')
        },
        async fetchEventsSession(eventId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'date', 'stock', 'ticketTypes', 'quota']
            params.filter = { eventId: eventId }
            await getAllItemsAction<Event, Store<'EventStore'>>('/events/sessions/list', params, this, 'sessions')
        },
        updateStock(cartState: CartState) {
            const quantity: number = cartState.items.reduce((acc, item: CartItem) => {
                if (item.ticketType.isProduct) {
                    return acc
                }
                return acc + item.tickets.length
            }, 0)
            if (this.currentEvent) {
                this.currentEvent._app.currentStock = Number(this.currentEvent.ticketing.stock) - (Number(this.currentEvent._app.bufferStock) + quantity)
            }
            if (this.currentSession) {
                this.currentSession._app.currentStock = Number(this.currentSession.stock) - (Number(this.currentSession._app.bufferStock) + quantity)
            }
        }
    },
    getters: {
        isRoomPlacement(): boolean {
            if (this.currentEvent && this.currentEvent.useRoomPlacement) {
                return true
            }
            return false
        },
        roomPlacementEventId(): string | null {
            const session = this.currentSession
            const event = this.currentEvent
            if (session) {
                return session.id
            }
            if (event) {
                return event.id
            }
            return null
        }
    }
})
