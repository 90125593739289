<template>
    <div class="mtable-ctn">
        <table cellspacing="0" cellpadding="0" class="mtable">
            <tr class="row--label" v-if="showLabels && labels">
                <th v-for="(label, labelIndex) in labels" :key="labelIndex">
                    {{ label.label }}
                </th>
            </tr>
            <tr
                @click="rowClicked(row)"
                @mouseenter="isActive = true"
                @mouseleave="isActive = false"
                class="row--data"
                :class="cellClass(rowIndex)"
                v-for="(row, rowIndex) in rows"
                :key="rowIndex"
            >
                <template v-for="(cell, cellIndex) in row" :key="`${rowIndex}_${cellIndex}`">
                    <td :class="{multi: Array.isArray(cell)}" v-if="cellIndex.toString() !== '$id'">
                        <template v-if="Array.isArray(cell)">
                            <template v-for="(atom, atomIndex) in cell" :key="atomIndex">
                                <p v-if="atomIndex==0">{{ atom }}</p>
                                <span v-else>{{ atom }}</span>
                            </template>
                        </template>
                        <template v-else>{{ cell }}</template>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>
<script setup lang="ts">
    import { ref, toRefs } from 'vue'
    const emit = defineEmits(['clicked'])

    interface Props {
        rows: object,
        labels: rowLabel[],
        showLabels: boolean,
        selectable: boolean
    }

    interface rowLabel {
        label: string,
        value: string,
        align?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        showLabels: false,
        labels: null,
        rows: null,
        selectable: false
    })

    const isActive = ref(false)

    const { rows, labels } = toRefs(props)

    const cellClass = (index) => {
        const classes = []
        if (index%2 === 0) {
            classes.push('odd')
        }
        else {
            classes.push('even')
        }
        if (props.selectable) {
            classes.push('selectable')
        }
        return classes
    }

    const rowClicked = ((row) => {
        emit('clicked', row);
    })

</script>