<template>
    <div class="page-guichet page-guichet--infos">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Guichet" :is-product="true" :show-quota="false" />
            <div class="content_inner">
                <div class="content_inner_inner">
                    <FormKit
                        id="buvetteInfosForm"
                        :actions="false"
                        type="form"
                        v-model="formData"
                        @submit-invalid="invalidSubmit"
                        @submit="handleSubmit">
                            <FormKitSchema
                                :schema="formKitSchema"
                            />
                    </FormKit>
                </div>
                <Cart :isProduct="true" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useEventStore } from '@/stores/EventStore'
    import { useTicketTypeStore } from '@/stores/TicketTypeStore'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import { onMounted, ref } from 'vue'
    import { useRouter } from 'vue-router'
    import { toast } from 'vue3-toastify';
    import { useI18n } from 'vue-i18n';
    import { useTicketTypeFormStore } from '@/stores/TicketTypeFormStore';
    import { useCartStore } from '@/stores/CartStore';
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { FormKitNode } from '@formkit/core';
    import { payloadOrderInfosType } from '@/interfaces/Types.definition'

    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const router = useRouter()
    const { t } = useI18n();
    const isReady = ref(false)
    const formData = ref([])
    const formKitSchema = ref<any>([
        {
            $el: 'div',
            attrs: {
                class: ['row', 'row--2cols', 'fields-ctn']
            },
            children: [
                {
                    $formkit: 'email',
                    name: 'email',
                    'suffix-icon':'email',
                    label: t('form.tickets.email.label'),
                    placeholder: t('form.tickets.email.placeholder'),
                    help: t('form.tickets.email.help'),
                    validation: 'string|email',
                },
                {
                    $formkit: 'text',
                    name: 'zone',
                    placeholder: t('form.tickets.zone.placeholder'),
                    label: t('form.tickets.zone.label'),
                    validation: '',
                    'suffix-icon':'number',
                },
            ]
        },
        {
            $formkit: 'textarea',
            name: 'additionalInfos',
            label: 'Informations complémentaires',
        },
        {
            $formkit: 'checkbox',
            name: 'sendInvoice',
            id: 'sendInvoice',
            label: 'Envoyer une facture',
        },
        {
            $el: 'div',
            name: 'invoiceInfos',
            if: '$get(sendInvoice).value',
            children: [
                {
                    $el: 'div',
                    attrs: {
                        class: ['row', 'row--2cols', 'fields-ctn']
                    },
                    children: [
                    {
                        $formkit: 'text',
                        name: 'firstName',
                        label: t('form.tickets.firstname.label'),
                        placeholder: t('form.tickets.firstname.placeholder'),
                        validation: 'string|length:2',
                    },
                    {
                        $formkit: 'text',
                        name: 'lastName',
                        label: t('form.tickets.lastname.label'),
                        placeholder: t('form.tickets.lastname.placeholder'),
                        validation: 'string|length:2'
                    },

                    {
                        $formkit: 'text',
                        name: 'company',
                        placeholder: t('form.tickets.company.placeholder'),
                        label: t('form.tickets.company.label'),
                        validation: 'string|length:2|required'
                    },
                    {
                        $formkit: 'text',
                        name: 'numTva',
                        placeholder: t('form.tickets.tva.placeholder'),
                        label: t('form.tickets.tva.label'),
                        validation: 'string|length:2'
                    },
                    {
                        $formkit: 'textarea',
                        name: 'address',
                        placeholder: t('form.tickets.address.placeholder'),
                        label: t('form.tickets.address.label'),
                        validation: 'string|length:2'
                    },
                    ]
                },
            ]
        }
    ])

    onMounted(async () => {
        if (!cartStore.order) {
            toast.error('Problème avec la commande')
            router.push({name: 'ticket', params: { eventId: eventStore.currentEvent?.id }})
        }

        if (eventStore.currentEvent?.id) {
            isReady.value = true
        }
    })

    /**
     * Formulaire incomplet
     * @param node
     */
    const invalidSubmit = (node: FormKitNode) => {
        toast.error('Veuillez compléter toutes les informations nécessaires')
    };

    /**
     * Transform the formdata object to a form data array ready for payload.
     * @param fields
     */
    const handleSubmit = async (fields: any) => {

        const formData: payloadOrderInfosType = {
            invoiceData: {
                firstName: fields.firstName ?? null,
                lastName: fields.lastName ?? null,
                email: fields.email ?? null,
                zone: fields.zone ?? null,
                additionalInfos: fields.additionalInfos ?? null,
                numTva: fields.numTva ?? null,
                company: fields.company ?? null
            },
            tickets: []
        }

        const updateOrder = await cartStore.updateOrderInfos(formData)
        if (updateOrder) {
            cartStore.nextStep()
        }
    };


</script>