import { RoomPlacementType } from "./RoomPlacement.type";
import { SeatsioClient, Region } from 'seatsio'
import { TicketTypeCategory } from "@/interfaces/TicketTypeCategory";
import { CartItem, CartTicket } from "@/interfaces/CartItem";
import { useEventStore } from "@/stores/EventStore";
import { useUserStore } from "@/stores/UserStore";
import * as Sentry from "@sentry/browser";


type BestAvailableObject = {
    objects: string[]
    objectDetails: {}
    nextToEachOther: boolean
}

type SeatsIoReportDetail = {
    byAvailability: any
    byObjectType: any
    bySection: any
    count: number
    byChannel: any
    byCategoryKey: any
    bySelectability: any
    byStatus: any
    byCategoryLabel: any
}

export type SeatsIoReport = {
    available: SeatsIoReportDetail
    booked: SeatsIoReportDetail
    disabled_by_social_distancing: SeatsIoReportDetail
    not_for_sale: SeatsIoReportDetail
    reservedByToken: SeatsIoReportDetail
}

export class RoomPlacement implements RoomPlacementType {

    reports: SeatsIoReport | false
    client: SeatsioClient
    eventId: string|null = null
    workspaceSecret: string|null = null
    workspacePublic: string|null = null

    /**
     *
     * @param workspaceSecret
     */
    constructor() {
        this.reports = false
        const eventStore = useEventStore()
        const userStore = useUserStore()
        this.eventId = eventStore.roomPlacementEventId
        if (userStore.organisation) {
            this.workspaceSecret = userStore.organisation.seatsIoWorkspaceSecret
            this.workspacePublic = userStore.organisation.seatsIoWorkspace
        }
        this.initClient()
    }

    private initClient() {
        if (this.workspaceSecret) {
            this.client = new SeatsioClient(Region.EU(), this.workspaceSecret)
            return true
        }
        Sentry.captureMessage('Organization has no SeatsIo WorkspaceSecretToken', 'error')
        return false
    }

    /**
     *
     * @param categories
     */
    public async updateStockCategories(categories: TicketTypeCategory[]) {
        categories.forEach(category => {
            if (this.reports) {
                try {
                    const stock: any = this.reports.available.byCategoryLabel[category.name]
                    if (stock) {
                        category.quota = stock
                        category.stock = stock
                    }
                    else {
                        category.quota = 50000000
                        category.stock = 0
                    }
                } catch (error: any) {
                    throw new Error(error.messages.join(', '), {cause: {
                        apiRequest: {
                            category: category.name
                        },
                        apiResponse: error
                    }})
                }
            }
        });
    }

    /**
     *
     * @param eventId
     * @returns
     */
    public async getSummaryReport(): Promise<SeatsIoReport|false> {
        try {
            this.reports = await this.client.eventReports.summaryByAvailabilityReason(this.eventId)
            return this.reports
        } catch (error: any) {
            throw new Error(error.messages.join(', '), {cause: {
                apiRequest: {
                    eventId: this.eventId
                },
                apiResponse: error
            }})
        }
    }

    /**
     *
     * @param seats
     * @returns
     */
    public async releaseSeats(seats: string[]): Promise<boolean> {
        if (seats.length) {
            try {
                await this.client.events.release(this.eventId, seats);
                return true
            } catch(error: any) {
                throw new Error(error.messages.join(', '), {cause: {
                    apiRequest: {
                        eventId: this.eventId,
                        seats
                    },
                    apiResponse: error
                }})
            }
        }
        return false
    }

    /**
     *
     * Relache les places pour les elements du panier.
     *
     * @param eventId
     * @param cartItems
     * @param categories
     * @returns
     */
    public async releaseAllSeats(cartItems: CartItem[]): Promise<boolean> {
        const seats = []
        for (const cartItem of cartItems) {
                for (const ticket of cartItem.tickets) {
                    if (ticket.seat?.length) {
                        console.log(ticket.seat[0]);
                        seats.push(ticket.seat[0])
                        delete ticket.seat
                    }
                }
        }
        if (seats.length) {
            try {
                await this.client.events.release(this.eventId, seats);
            } catch(error: any) {
                throw new Error(error.messages.join(', '), {cause: {
                    apiRequest: {
                        eventId: this.eventId,
                        seats
                    },
                    apiResponse: error
                }})
            }
        }
        return true
    }

    /**
     *
     * Reserve les places pour les elements du panier.
     *
     * @param eventId
     * @param cartItems
     * @param categories
     * @returns
     */
    public async holdSeats(cartItems: CartItem[], categories: TicketTypeCategory[]): Promise<boolean> {
        await this.releaseAllSeats(cartItems)
        const holdToken: { holdToken: string, expiresAt: Date } = await this.client.holdTokens.create();
        const seatsPerCategory: {category: TicketTypeCategory, quantity: number, tickets?: CartTicket[]}[] = []
        cartItems.forEach(cartItem => {
            const category = categories.find(elCat => elCat.id == cartItem.ticketType.ticketTypeCategoryId)
            if (category) {
                let index = -1
                if (seatsPerCategory.length) {
                    index = seatsPerCategory.findIndex(el => el.category.name == category?.name)
                    if (index > -1) {
                        seatsPerCategory[index].quantity += cartItem.quantity
                        seatsPerCategory[index].tickets = seatsPerCategory[index].tickets?.concat(cartItem.tickets)
                    }
                }
                if (index == -1) {
                    seatsPerCategory.push({
                        category: category,
                        quantity: cartItem.quantity,
                        tickets: cartItem.tickets
                    })
                }
            }
        })
        for (const el of seatsPerCategory) {
            try {
                const result: BestAvailableObject = await this.client.events.holdBestAvailable(this.eventId, el.quantity, holdToken.holdToken, [el.category.name]);
                el.tickets?.forEach((ticket: CartTicket, index: number) => {
                    if (result.objects[index]) {
                        ticket.seat = [result.objects[index]]
                    }
                })
            } catch (error: any) {
                throw new Error(error.messages.join(', '), {cause: {
                    apiRequest: {
                        eventId: this.eventId,
                        quantity: el.quantity,
                        holdToken: holdToken.holdToken,
                        category: el.category.name
                    },
                    apiResponse: error
                }})
            }
        }
        return true
    }

    /**
     *
     * @param cartItems
     * @returns
     */
    public async bookSeats(cartItems: CartItem[]): Promise<boolean> {
        const objects = []
        cartItems.forEach(cartItem => {
            cartItem.tickets.forEach(ticket => {
                if (ticket.seat.length > 0) {
                    objects.push(ticket.seat[0])
                }
            })
        })
        try {
            if (objects.length) {
                await this.client.events.book(this.eventId, objects);
                return true
            }
        } catch (error: any) {
            throw new Error(error.messages.join(', '), {cause: {
                apiRequest: {
                    eventId: this.eventId,
                    objects,
                },
                apiResponse: error
            }})
        }
        return false
    }

}