<template>
    <div class="burger" @click="appStore.menuIsOpen = !appStore.menuIsOpen">
        <IconSvg :name="'burger'" />
    </div>
    <div @click="appStore.menuIsOpen = false" class="sidebar_overlay" :class="{isVisible: appStore.menuIsOpen}"></div>

    <div class="sidebar" :class="{isOpen: appStore.menuIsOpen}">
        <div class="logo">
            <IconSvg name="logo-white" />
        </div>
        <div class="menu" @click="appStore.menuIsOpen = false">
            <router-link :class="{active: isActive('guichet')}" class="menu-item" :to="{ name: 'ticket', params: {eventId: eventStore.currentEvent.id}}">
                <div class="picto">
                    <IconSvg class="type-fill" :name="'ticket'" />
                </div>
                <div class="label">{{ $t('global.menu.ticket_office') }}</div>
            </router-link>
            <router-link :class="{active: isActive('buvette')}" class="menu-item" :to="{ name: 'buvette'}">
                <div class="picto">
                    <IconSvg class="type-fill" :name="'glass'" />
                </div>
                <div class="label">{{ $t('global.menu.digibuvette') }}</div>
            </router-link>
            <router-link class="menu-item" :to="{ name: 'orders'}" :class="{active: isActive('orders')}">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'list'" />
                </div>
                <div class="label">{{ $t('global.menu.orders') }}</div>
            </router-link>
            <router-link class="menu-item" :to="{ name: 'stats'}" :class="{active: isActive('stats')}">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'stats'" />
                </div>
                <div class="label">{{ $t('global.menu.stats') }}</div>
            </router-link>
            <router-link class="menu-item" :to="{ name: 'settings'}" :class="{active: isActive('settings')}">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'settings'" />
                </div>
                <div class="label">{{ $t('global.menu.settings') }}</div>
            </router-link>
            <!--
            <div class="menu-item"  @click="toggleFullScreen" :class="{active: isActive('fullscreen')}">
                <template v-if="isFullscreen == false">
                    <div class="picto">
                        <IconSvg class="type-stroke" :name="'maximize'" />
                    </div>
                    <div class="label">{{ $t('global.menu.fullscreen') }}</div>
                </template>
                <template v-if="isFullscreen == true">
                    <div class="picto">
                        <IconSvg class="type-stroke" :name="'minimize'" />
                    </div>
                    <div class="label">{{ $t('global.menu.fullscreenQuit') }}</div>
                </template>
            </div>
            -->
        </div>

        <div class="session">
            <div class="menu-item" @click="vfm.open(appStore.pauseUserSessionModalId)">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'pause'" />
                </div>
                <div class="label">{{ $t('global.menu.pause') }}</div>
            </div>
            <div class="menu-item" @click="vfm.open(appStore.closeUserSessionModalId)">
                <div class="picto">
                    <IconSvg class="type-stroke" :name="'close'" />
                </div>
                <div class="label">{{ $t('global.menu.close') }}</div>
            </div>
        </div>
    </div>


</template>
<script setup lang="ts">
    import { api as fullscreen } from 'vue-fullscreen'
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { ref } from "vue";
    import { useAppStore } from "@/stores/AppStore";
    import { useEventStore } from "@/stores/EventStore";
    import { useRoute } from "vue-router";
    import { useVfm } from "vue-final-modal";

    const eventStore = useEventStore()
    const appStore = useAppStore()
    const route = useRoute()
    const isFullscreen = ref(false)

    const toggleFullScreen = async () => {
        await fullscreen.toggle(null, {
            callback: (state) => {
                isFullscreen.value = state
                console.log(state);
            }
        })
    }

    const vfm = useVfm()

    const isActive = (menuItem: string): boolean => {
        switch (menuItem) {
            case 'guichet':
                if (['ticket', 'infos', 'payment'].includes(route.name.toString())) {
                    return true
                }
                break;
            case 'buvette':
                if (['buvette', 'buvette_infos', 'payment'].includes(route.name.toString())) {
                    return true
                }
                break;
            case 'orders':
                if (['orders', 'order_details'].includes(route.name.toString())) {
                    return true
                }
                break;
            case 'stats':
                if (['stats'].includes(route.name.toString())) {
                    return true
                }
                break;
            case 'settings':
                if (['settings'].includes(route.name.toString())) {
                    return true
                }
                break;
        }
        return false
    }


</script>