import { GetParams } from "@/interfaces/Types.definition";

export class QueryParams {
    constructor() {}

    translate(params: GetParams) {
        let select = null;
        let populate = null;
        let filter = null;
        let sort = null;
        let page = null
        let limit = null
        let template = null

        let query: any = params

        if (params.select) {
            select = this.makeSelect(params.select);
            query['select'] = select
        }
        if (params.populate) {
            populate = this.makePopulate(params.populate);
            query['populate'] = populate
        }
        if (params.filter) {
            filter = this.makeFilter(params.filter);
            query['filter'] = filter
        }
        if (params.sort) {
          sort = this.makeSort(params.sort);
          query['sort'] = sort
        }
        if (params.page || params.page == 0) {
          page = this.makePage(params.page);
          query['page'] = page
        }
        if (params.limit || params.limit == 0) {
          limit = this.makeLimit(params.limit);
          query['limit'] = limit
        }
        if (params.template) {
          template = this.makeTemplate(params.template);
          query['template'] = template
        }
        return query
    }

    makeSort(data: [string, number][]): string | null {
      let sort = null;
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          sort = `${data[i][0]}==${data[i][1]}`;
        } else {
          sort = `${sort}__${data[i][0]}==${data[i][1]}`;
        }
      }
      return sort;
    }

    makePage(data: number): string {
      return `${data}`;
    }

    makeLimit(data: number): string {
      return `${data}`;
    }

    makeTemplate(data: 'base' | 'full'): string {
      return `${data}`;
    }

    makeFilter(data: any): string | null {
        let filter = null;
        let keys = Object.keys(data) as (any)[];
        for (let i = 0; i < keys.length; ++i) {
          if (i == 0) {
            filter = `${String(keys[i])}==${data[keys[i]]}`;
          } else {
            filter = `${filter}__${String(keys[i])}==${data[keys[i]]}`;
          }
        }
        return filter;
      }

    makePopulate(data: string[]): string | null {
        let sort = null;
        for (let i = 0; i < data.length; ++i) {
          if (i == 0) {
            sort = data[i];
          } else {
            sort = `${sort}__${data[i]}`;
          }
        }
        return sort;
      }

    makeSelect(data: string[]): string | null {
        let sort = null;
        for (let i = 0; i < data.length; ++i) {
            if (i == 0) {
                sort = String(data[i]);
            } else {
                sort = String(`${sort}__${String(data[i])}`);
            }
        }
        return sort;
    }
}