<template>
    <div class="page-events">
        <div class="container">
            <div class="inner">
                <h1 class="title title--h1 title--separator">{{ $t('auth.eventsPage.title') }}</h1>
                <div v-if="eventStore.events && eventStore.events.length > 6" class="filter-orga">
                    <FormKit
                        type="text"
                        name="filter"
                        v-model="filterText"
                        :placeholder="$t('auth.eventsPage.filterPlaceHolder')"
                    />
                </div>
                <div v-if="filteredEvents.length" class="list-events">
                    <CardEvent @clicked="selectEvent" :event="event" v-for="event in filteredEvents" :key="event.id" />
                </div>
                <div v-else class="empty-events">
                    <h2 class="title-h2">
                        Aucun résultat 😢
                    </h2>
                </div>
            </div>
            <SessionLogoutVue :logout="true" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useEventStore } from '@/stores/EventStore'
    import CardEvent from '@/components/molecules/CardEvent.vue'
    import { Event } from '@/interfaces/Event';
    import { useUserStore } from '@/stores/UserStore';
    import { toast } from 'vue3-toastify';
    import shoetest from 'shoetest'
    import SessionLogoutVue from '@/components/atoms/SessionLogout.vue'

    const eventStore = useEventStore()
    const route = useRoute()
    const router = useRouter()
    const userStore = useUserStore()
    const filterText = ref(null)

    onMounted(async () => {
        const id = String(route.params.organisationId)
        await eventStore.listPublishedEvents(id)
    })

    const selectEvent = async (event: Event) => {
        try {
            eventStore.currentEvent = event
            await userStore.createSellingSession(event)
            if (event.dates.type == 'sessions') {
                router.push({name: 'event_sessions', params: { eventId: event.id}});            }
            else {
                router.push({name: 'ticket', params: { eventId: event.id}});
            }
        } catch (error) {
            toast.error('Impossible de créer une session de vente')
        }
    }

    const filteredEvents = computed(() => {
        if (!eventStore.events) {
            return []
        }

        const organisationId = String(route.params.organisationId)

        return eventStore.events?.filter((el: Event) => {
            if (el.organisationId !== organisationId) {
                return false
            }
            if (!filterText.value) {
                return true
            }
            return shoetest.test(filterText.value.trim(), el.name)
        })
    })

</script>