import { StockBuffer, TicketType } from "@/interfaces/TicketType";
import { TicketTypeCategory } from "@/interfaces/TicketTypeCategory";
import { DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import axios from "@/tools/Axios/axios";
import { defineStore, Store } from "pinia";
import { CartState } from "./CartStore";
import { getAllItemsAction } from "./common/actions";
import { useEventStore as eventStore } from "./EventStore";
import { useUserStore as userStore } from "./UserStore";
import { Event } from "@/interfaces/Event";
import { RoomPlacement } from "@/tools/RoomPlacement/RoomPlacement";
import * as Sentry from "@sentry/browser";
import { toast } from "vue3-toastify";
import { Addon } from "@/interfaces/Addon";
import { useAppStore } from "./AppStore";

type TicketsPerCategory = {
    category: TicketTypeCategory | null
    tickets: TicketType[]
    isDefault: boolean
}

export interface TicketTypeState {
    ticketTypes: TicketType[]
    categories: TicketTypeCategory[]
    bufferStock: null
    addons: Addon[]
}

export const useTicketTypeStore = defineStore("TicketTypeStore", {

    state: (): TicketTypeState => {
        return {
            ticketTypes: [],
            categories: [],
            bufferStock: null,
            addons: []
        }
    },
    actions: {
        isDisabledTicketType(ticketType: TicketType, inCart: number) {

            let minSelectable = 1
            let maxSelectable = null

            if (Number(ticketType.tickets?.minimumSelectable)) {
                minSelectable = Number(ticketType.tickets?.minimumSelectable)
            }
            if (Number(ticketType.tickets?.maximumSelectable)) {
                maxSelectable = Number(ticketType.tickets?.maximumSelectable)
            }

            // Si Quota défini.
            if (ticketType.quota) {
                if (Number(ticketType.currentStock) <= 0) {
                    return true
                }
                if (minSelectable > Number(ticketType.currentStock)) {
                    return true
                }
            }

            if (maxSelectable) {
                if ((inCart + minSelectable) > maxSelectable) {
                    return true
                }
            }

            // Si la category est elle-même disabled, on disable le tickettype.
            if (ticketType.ticketTypeCategoryId) {
                const category = this.categories.find(el => el.id == ticketType.ticketTypeCategoryId)
                if (category) {
                    return category.isDisabled
                }
            }

            return false
        },
        /**
         * Update du stock restant prenant en compte le panier.
         *
         * @param cartState
         */
        updateCurrentStock(cartState: CartState) {

            const inCartPerCategory: any = {}

            /**
             * Mise a jour des stocks des ticketsTypes.
             */
            this.ticketTypes.forEach(ticketType => {
                let quantityInCart = 0
                const inCart = cartState.items.find(cartItem => cartItem.ticketType.id === ticketType.id)
                if (inCart) {
                    quantityInCart = inCart.quantity
                }
                if (ticketType.ticketTypeCategoryId) {
                    if (ticketType.ticketTypeCategoryId in inCartPerCategory) {
                        inCartPerCategory[ticketType.ticketTypeCategoryId] += quantityInCart
                    }
                    else {
                        inCartPerCategory[ticketType.ticketTypeCategoryId] = quantityInCart
                    }
                }
                ticketType.currentStock = Number(ticketType.stock) - Number(ticketType.bufferStock) - quantityInCart
            })

            /**
             * Mise a jour des stocks des Categories et de l'état "isDisabled".
             */
            this.categories.forEach(category => {
                const inCart = inCartPerCategory[category.id] || 0
                category.currentStock = Number(category.stock) - Number(category.bufferStock) - inCart
                category.isDisabled = (Boolean(category.quota) && category.currentStock <= 0)
            })

            /**
             * Une fois le stock des categories à jour, on met a jour l'état "isDisabled"
             * du ticketType.
             */
            this.ticketTypes.forEach(ticketType => {
                let quantityInCart = 0
                const inCart = cartState.items.find(cartItem => cartItem.ticketType.id === ticketType.id)
                if (inCart) {
                    quantityInCart = inCart.quantity
                }
                ticketType.isDisabled = this.isDisabledTicketType(ticketType, quantityInCart)
            })
        },
        async fetchAll(isProduct: boolean = false) {
            await this.fetch(isProduct)
            await this.fetchCategories(isProduct)
            /** Si placement numeroté, on récupère le stock directement via l'API */
            if (!isProduct && eventStore().isRoomPlacement) {
                this.fetchRoomPlacementStock()
            }
            await this.fetchBufferStock()
        },
        /**
         * Recupération des categories.
         */
        async fetchCategories(isProduct: boolean = false) {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'isOpened', 'stock', 'quota', 'order', 'isProduct']
            params.filter = {
                eventId: eventStore().currentEvent?.id,
                isProduct,
                isOpened: true
            }
            await getAllItemsAction<Event, Store<'TicketTypeStore'>>('/events/ticket-type-categories/list', params, this, 'categories')
        },
        async fetch(isProduct: boolean = false) {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'order', 'addons', 'price', 'isVisible', "inTicketOffice", 'ticketQuota', 'description', 'category', 'ticketTypeCategoryId', 'stock', 'tickets', 'scheduled', 'quota', 'isProduct']
            params.filter = {
                eventId: eventStore().currentEvent?.id,
                inTicketOffice: true,
                isProduct
            }
            await getAllItemsAction<Event, Store<'TicketTypeStore'>>('/events/ticket-types/list', params, this, 'ticketTypes')

        },
        async fetchBufferStock() {
            try {
                const { data } = await axios.get(`/events/buffer-stock/${eventStore().currentEvent?.id}`)
                    if (data.tickets.length) {
                        data.tickets.forEach((buffer: StockBuffer) => {
                            // Si placement numéroté, le stock tampon est géré directement par Seats.io.
                            if (eventStore().isRoomPlacement) {
                                buffer.stock_buffer = 0
                            }
                            if (this.ticketTypes) {
                                const ticketTypeIndex = this.ticketTypes?.findIndex(el => el.id == buffer.id)
                                if (ticketTypeIndex != -1) {
                                    const currentStock = Number(this.ticketTypes[ticketTypeIndex].stock) - buffer.stock_buffer
                                    const hasQuota: boolean = Boolean(this.ticketTypes[ticketTypeIndex].quota)
                                    this.ticketTypes[ticketTypeIndex].bufferStock = buffer.stock_buffer
                                    this.ticketTypes[ticketTypeIndex].currentStock = currentStock
                                    this.ticketTypes[ticketTypeIndex].isDisabled = (hasQuota && (currentStock <= 0))
                                }
                            }
                        })

                        // Calcul du nombre total de ticket en buffer.
                        let totalBuffer: number = data.tickets.reduce((acc: number, currentVal: StockBuffer) => {
                            if (currentVal.isProduct) {
                                return acc
                            }
                            return (acc + Number(currentVal.stock_buffer))
                        }, 0)

                        // Pas de calcul du buffer en cas de placement numéroté (partie délégué à seats.io)
                        if (eventStore().isRoomPlacement) {
                            totalBuffer = 0
                        }

                        // MAJ stock global événement.
                        eventStore().currentEvent['_app'] = {
                            bufferStock: totalBuffer,
                            currentStock: Number(eventStore().currentEvent?.ticketing.stock) - Number(totalBuffer)
                        }

                        // MAJ stock global session.
                        if (eventStore().currentSession) {
                            eventStore().currentSession['_app'] = {
                                bufferStock: totalBuffer,
                                currentStock: Number(eventStore().currentSession?.stock) - Number(totalBuffer)
                            }
                        }
                    }

                    this.categories.forEach(elCategory => {
                        elCategory.currentStock = 0
                        elCategory.isDisabled = false
                        const ticketTypes = this.ticketTypes.filter(el => (elCategory.id == el.ticketTypeCategoryId))
                        let bufferStockCategory = ticketTypes.reduce((acc, currentVal: TicketType) => (acc + Number(currentVal.bufferStock)), 0);
                        if (isNaN(bufferStockCategory)) {
                            bufferStockCategory = 0
                        }
                        elCategory.bufferStock = bufferStockCategory
                        elCategory.currentStock = Number(elCategory.stock) - bufferStockCategory
                        elCategory.isDisabled = (Boolean(elCategory.quota) && elCategory.currentStock <= 0)
                        if (elCategory.isDisabled && ticketTypes.length) {
                            ticketTypes.forEach(ticketType => {
                                ticketType.isDisabled = true
                            })
                        }
                    })
            } catch (error) {
                console.log('=====> ', error);

            }
        },
        /**
         * Pour le placement numéroté, on récupère directement le stock via l'API.
         * On ne tient pas compte du stock OandB.
         */
        async fetchRoomPlacementStock() {
            const secret = userStore().organisation?.seatsIoWorkspaceSecret
            if (secret) {
                if (this.categories.length) {
                    const roomPlacement = new RoomPlacement()
                    try {
                        await roomPlacement.getSummaryReport()
                        roomPlacement.updateStockCategories(this.categories)
                    } catch (error: any) {
                        Sentry.captureException(error, { extra: error.cause })
                        toast.error('Impossible de récupérer le stock des places restantes.');
                    }
                }
            }
        },
        /**
         * Fetch current event addons.
         */
        async fetchAddons() {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'name', 'description', 'ticketTypeIds', 'taxId', 'price', 'quota', 'stock']
            params.filter = {
                eventId: eventStore().currentEvent?.id,
                inTicketOffice: true
            }
            await getAllItemsAction<Addon, Store<'TicketTypeStore'>>('/events/addons/list', params, this, 'addons')
        }
    },
    getters: {
        ticketsWithCategory(): TicketType[] {
            const ticketTypes = this.ticketTypes.map((ticketType: TicketType) => {
                ticketType.ticketTypeCategory = null
                if (ticketType.ticketTypeCategoryId && this.categories) {
                    const foundCategory = this.categories.find((ticketTypeCategory: TicketTypeCategory) => ticketTypeCategory.id == ticketType.ticketTypeCategoryId)
                    if (foundCategory) {
                        ticketType.ticketTypeCategory = foundCategory
                    }
                }
                return ticketType
            })
            return ticketTypes
        },
        ticketsPerCategory() {
            let ticketsPerCat: TicketsPerCategory[] = []
            // Creation categorie par default pour les tickettypes sans catégorie.

            let ticketTypes = this.ticketTypes
            if (this.ticketTypes) {

                /**
                 * On filtre les TicketTypes si événements session.
                 */
                if (eventStore().currentSession) {
                    if (eventStore().currentSession?.ticketTypes.restrict) {
                        ticketTypes = this.ticketTypes.filter((el: TicketType) => {
                            return eventStore().currentSession?.ticketTypes.ids.includes(el.id)
                        })
                    }
                }
                ticketsPerCat.push({
                    category: null,
                    tickets: ticketTypes.filter(el => !el.ticketTypeCategoryId),
                    isDefault: true,
                })
                if (this.categories) {
                    this.categories.forEach((elCategory: TicketTypeCategory) => {
                        let tickets: TicketType[] = []
                        if (ticketTypes) {
                            tickets = ticketTypes.filter(el => (elCategory.id == el.ticketTypeCategoryId))
                        }
                        ticketsPerCat.push({
                            category: elCategory,
                            tickets,
                            isDefault: false
                        })
                    })
                }
            }

            return ticketsPerCat
        }
    }
})