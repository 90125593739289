import { defineStore } from "pinia"
import { ref } from "vue"
import { useCartStore } from "./CartStore"
import { useEventStore } from "./EventStore"
import { useTicketTypeStore } from "./TicketTypeStore"
import { useUserStore } from "./UserStore"
import * as Sentry from "@sentry/browser";

export const useAppStore = defineStore('AppStore', () => {

    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const userStore = useUserStore()
    const ticketTypeStore = useTicketTypeStore()

    /**
     * Modal ID
     */
    const closeUserSessionModalId = Symbol('closeUserSessionModalId')
    const pauseUserSessionModalId = Symbol('pauseUserSessionModalId')

    /**
     * STATE
     */
    const isLoading = ref(false)
    const menuIsOpen = ref(false)
    const cartIsOpen = ref(false)

    /**
     * ACTIONS
     */
    const logout = () => {
        userStore.logOut()
        eventStore.$reset()
        cartStore.$reset()
        ticketTypeStore.$reset()
    }

    const closeUserSession = async () => {
        await userStore.closeSession()
        logout()
    }

    /**
     * SUBSCRIBERS
     */
    cartStore.$subscribe((mutation, state) => {
        const events = mutation.events
        if (events) {
            if (!Array.isArray(events['key'])) {
                if (events['key'] && events['key'] !== 'currentStock') {
                    eventStore.updateStock(state)
                    ticketTypeStore.updateCurrentStock(state)
                }
            }
        }
    })

    eventStore.$subscribe((mutation, state) => {
        const events = mutation.events
        if (events) {
            if (!Array.isArray(events['key'])) {
                if (events['key'] && events['key'] == 'currentEvent') {
                    Sentry.setContext('AppEvent', {
                        id: state.currentEvent?.id,
                        name: state.currentEvent?.name
                    })
                }
                if (events['key'] && events['key'] == 'currentSession') {
                    Sentry.setContext('AppEventSession', {
                        id: state.currentSession?.id,
                        date: state.currentSession?.date
                    })
                }
            }
        }
    })

    return {
        isLoading,
        menuIsOpen,
        cartIsOpen,
        logout,
        closeUserSession,
        closeUserSessionModalId,
        pauseUserSessionModalId
    }

  })
