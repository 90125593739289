export default {
    generic: {
        free: 'Gratuit',
        noresult: 'Aucun résultat 😢'
    },
    statusBar: {
        products: 'Produits',
        prices: 'Tarifs',
        infos: 'Informations',
        payment: 'Paiement'
    },
    cart: {
        cancel: "Annuler la commande",
        order: "Passer la commande",
        order_small: "Commander",
        empty_message: "Aucun ticket pour le moment",
        empty_product_message: "Aucun produit pour le moment",
        total: "Total",
        fees: "Frais de service",
        leftToPay: "Restant dû"
    },
    toast: {
        welcome: 'Bienvenue {name} 👋',
        ticket_type: {
            cant_add: 'Plus de stock disponible pour ce ticket'
        }
    },
    tpe: {
        collectMessage: 'En attente du paiement de ',
        cancelCollect: 'Annuler'
    },
    summary: {
        title: 'Commande enregistrée',
        new_order: 'Nouvelle commande',
        show_order: 'Voir la commande',
        print_order: 'Imprimer la commande'
    },
    pages: {
        resume: {
            title: 'Vous revoilà !',
            description: "Votre compte opérateur a débuté une session le {date}. Les données de vente ont été rechargées dans votre guichet. A l'issue des ventes, pensez à fermer votre guichet pour générer votre sortie de caisse.",
            btnLabel: "Reprendre ma session"
        }
    },
    products: {
        empty: 'Aucun produit...'
    },
    global: {
        menu: {
            ticket_office: "Guichet",
            orders: "Commandes",
            stats: "Stats",
            settings: "Réglages",
            pause: "Suspendre",
            close: "Terminer",
            logout: "Déconnexion",
            digibuvette: "Buvette",
            fullscreen: "Plein écran",
            fullscreenQuit: "Quitter plein écran"
        }
    },
    infosPage: {
        order_infos: "Informations commande",
        tickets_infos: "Informations tickets"
    },
    paymentPage: {
        otherPayments: "Autres moyens de paiement",
        validate: "Valider le paiement"
    },
    form: {
        tickets: {
            email: {
                label: "Email",
                placeholder: "Email",
                help: "Si l'email est renseigné, les billets seront automatiquement envoyés sur cette adresse après achat"
            },
            firstname: {
                label: "Prénom",
                placeholder: "Tony"
            },
            zone: {
                label: "Emplacement",
                placeholder: "Loge B12"
            },
            infos: {
                label: "Informations additionnelles",
                placeholder: ""
            },
            lastname: {
                label: "Nom",
                placeholder: "Santacruz"
            },
            company: {
                label: "Société",
                placeholder: "OandB"
            },
            tva: {
                label: "Numéro de TVA",
                placeholder: "FRXXXXXXXXXXX"
            },
            address: {
                label: "Adresse",
                placeholder: "11 rue du Languedoc\n31000 Toulouse"
            }
        }
    },
    auth: {
        loginPage: {
            title: 'Bien le bonjour. 👋',
            headMessage: 'Veuillez vous connecter pour accéder à votre espace guichet.',
            form: {
                email: {
                    placeholder: "michael.schott{'@'}dundermifflin.com",
                    label: 'Adresse e-mail'
                },
                password: {
                    placeholder: 'Restons discrets ;)',
                    label: 'Mot de passe'
                },
                submit: 'Connexion'
            }
        },
        organisationPage: {
            title: 'Choisissez une organisation',
            filterPlaceHolder: 'Filtrer les résultats',
            card: {
                eventInProgress: '{number} événement(s) en cours',
                noEventInProgress: 'Aucun événement en cours'
            }
        },
        eventsPage: {
            title: 'Choisissez un événement',
            filterPlaceHolder: 'Filtrer les résultats',
        },
        sessionsPage: {
            title: 'Choisissez une session'
        }
    }
};
