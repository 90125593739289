<template>
    <div @click="cardClicked(session)" class="card card__session">
        <div class="top">
            <h3 class="card--title">{{ date.date }}</h3>
            <p class="hour">{{ date.hour }}</p>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { EventSession } from "@/interfaces/EventSession";
    import { computed } from "vue";
    const emit = defineEmits(['clicked'])
    interface Props {
        session: EventSession
    }
    const props = withDefaults(defineProps<Props>(), {})

    const date = computed(() => {
        if (props.session.date) {
            const date = new Date(props.session.date)
            return {
                'date': date.toLocaleDateString('fr', { weekday: "short", day: "numeric", month: "short", year: "numeric" }),
                'hour': date.toLocaleTimeString('fr', {hour:"numeric", "minute":"numeric"})
            }
        }
        return null
    })

    const cardClicked = (session: EventSession) => {
        emit('clicked', session);
    }
</script>