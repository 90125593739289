// https://api.v3.oandb.eu/v1/forms/list?filter=eventId%3D%3D6421aab7bf0fa253f482adfb&select=location__name__ticketTypes
// https://api.v3.oandb.eu/v1/events/addons/list?filter=eventId%3D%3D6421aab7bf0fa253f482adfb&select=name__price__taxId__ticketTypeIds__stock__quota
// https://api.v3.oandb.eu/v1/forms/fields/list?filter=formId%3D%3D643970743947d977fea52d88&select=type__label__isRequired__description__constraints__options

import { defineStore, Store } from "pinia";
import { TicketTypeForm } from "@/interfaces/TicketTypeForm";
import { APIListResponse, DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import { getAllItemsAction } from "./common/actions";
import axios from "@/tools/Axios/axios";
import { FormField } from "@/interfaces/FormField";
import { useEventStore as eventStore } from "./EventStore";

export interface TicketTypeFormState {
    forms: TicketTypeForm[] | null,
}

export const useTicketTypeFormStore = defineStore("TicketTypeFormStore", {
    state: (): TicketTypeFormState => {
        return {
            forms: null,
        }
    },
    actions: {
        async fetchForms() {
            let params: GetParams = DefaultGetParams()
            params.select = ['id', 'location', 'name', 'ticketTypes']
            params.filter = { eventId:  eventStore().currentEvent?.id, }
            await getAllItemsAction<TicketTypeForm, Store<'TicketTypeFormStore'>>('/forms/list', params, this, 'forms')
            await this.fetchFormFields()
        },
        async fetchFormFields() {
            if (this.forms) {
                for (const form of this.forms) {
                    let params: GetParams = DefaultGetParams()
                    params.select = ['id', 'type', 'label', 'isRequired', 'description', 'constraints', 'options']
                    params.filter = { formId: form.id }
                    const { data }: APIListResponse<FormField> = await axios.get('/forms/fields/list', {params});
                    if (data) {
                        form.fields = data.data
                    }
                }
            }
        }
    }
})