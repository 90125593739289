<template>
<div
    @mouseenter="mouseEnter()"
    @mouseleave="mouseLeave()" class="cart-item"
    :class="{
        opened: isOpen,
        disabled: item.ticketType.isDisabled,
        deleting: isDeleting,
        updating: isUpdating
    }">
    <div class="top" :class="{with_seats: seats.length}">
        <div class="quantity">
            <div @click="deleteItem()" class="action delete">
                <IconSvg width="28" class="icon" :name="'trash'" />
            </div>
        </div>
        <div @click="mouseClick()" class="name_price" :class="{with_seats: seats.length}">
            <div class="name">
                <span class="qty"><span>{{ item.quantity }}</span></span>
                <span class="label">
                    {{ item.ticketType.name }}
                </span>
            </div>
            <div class="price">
                {{ formattedPrice }}
            </div>
        </div>
    </div>
    <div class="bottom" v-if="seats.length || addons.length">
        <div class="seats" v-if="seats.length">
            <span class="label">Places : </span>
            <span class="value">
                {{ seats.join(', ') }}
            </span>
        </div>
        <div class="addons" v-if="addons.length">
            <span class="label">Options : </span>
            <span class="value">
                {{ addons.join(', ') }}
            </span>
        </div>
    </div>
</div>

</template>
<script setup lang="ts">
    import { CartItem } from "@/interfaces/CartItem"
    import { TicketType } from '@/interfaces/TicketType'
    import { useCartStore } from "@/stores/CartStore"
    import { AppPrice } from "@/tools/Price"
    import { computed, ref } from "vue"
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { debounce } from "lodash"
    import { storeToRefs } from 'pinia'
    interface Props {
        item: CartItem,
        deletable: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        deletable: false
    })
    const cartStore = useCartStore()

    const isOpen = ref(false)
    const isMouseEnter = ref(false)
    const isDeleting = ref(false)
    const isUpdating = ref(false)
    const formattedPrice = computed(() => {
        return new AppPrice(Number(props.item.ticketType.price)).divide100()
    })

    const addons = computed(() => {
        const addonsObject = {}
        props.item.tickets.forEach(ticket => {
            if (ticket._app.addons.length) {
                ticket._app.addons.forEach(addon => {
                    const qty = (addonsObject[addon.id]) ? addonsObject[addon.id].qty += 1 : 1
                    addonsObject[addon.id] = {
                        name: `${addon.name} (${new AppPrice(addon.price).divide100()})`,
                        qty
                    }
                })
            }
        })
        const addonsArray = Object.values(addonsObject)
        if (addonsArray.length) {
            return addonsArray.map((addon: {qty: number, name: string}) => `${addon.qty}x ${addon.name}`)
        }
        return []
    })

    const seats = computed(() => {
        const seatsArray: string[] = []
        props.item.tickets.forEach(ticket => {
            if (ticket.seat) {
                seatsArray.push(ticket.seat[0])
            }
        })
        return seatsArray
    })

    const closeQty = debounce(() => {
        if (!isMouseEnter.value) {
            isOpen.value = false
        }
    }, 500)

    const mouseLeave = () => {
        if (props.deletable) {
            isMouseEnter.value = false
            closeQty()
        }
    }

    const mouseClick = () => {
        if (props.deletable) {
            isOpen.value = true
        }
    }

    const mouseEnter = () => {
        if (props.deletable) {
            isMouseEnter.value = true
            isOpen.value = true
        }
    }

    const deleteItem = () => {
        if (props.deletable) {
            isDeleting.value = true
            isOpen.value = false
            setTimeout(() => {
                cartStore.deleteItem(props.item.ticketType)
                isDeleting.value = false
            }, 250);
        }
    }

    cartStore.$subscribe((mutation: any, state) => {
        if (mutation.events) {
            if ('target' in mutation.events) {
                if (String(mutation.events.target.id)  === props.item.ticketType.id) {
                    isUpdating.value = false
                    isUpdating.value = true
                    setTimeout(() => {
                    isUpdating.value = false
                    }, 150);
                }
            }
        }
    })

</script>