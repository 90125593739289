<template>
    <div id="tpeCollector" :class="classes">
        <div class="content">
            <div class="icon">
                <IconSvg name="creditcard" />
            </div>
            <h3 class="title">{{ $t('tpe.collectMessage') }} <span>{{ price }}</span></h3>
            <Button variant="secondary" class="cancel" @click="tpeStore.cancelCollectPayment">{{ $t('tpe.cancelCollect') }}</Button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useTpeStore } from "@/stores/TpeStore";
    import { computed } from "vue";
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { AppPrice } from "@/tools/Price";
    import Button from '@/components/atoms/Button.vue'

    const tpeStore = useTpeStore()

    const price = computed(() => {
        let amount = 0
        if (tpeStore.payment_intent) {
            amount = tpeStore.payment_intent.amount
        }
        return new AppPrice(amount).divide100()
    })

    const classes = computed(() => {
        return {
            active: (tpeStore.collectInProgress && tpeStore.terminal)
        }
    })
</script>