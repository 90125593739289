import Axios from 'axios';
import { useUserStore } from '@/stores/UserStore'
import { useAppStore } from '@/stores/AppStore'
import { QueryParams } from '@/stores/common/query_params'
import { debounce } from 'lodash';

const query_params = new QueryParams()

const axios = Axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 10000,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
    },
})

// Global loader.
axios.interceptors.request.use((config) => {
        const appStore = useAppStore()
        appStore.isLoading = true
        return config
    }
);

const stopLoader = debounce(() => {
    const appStore = useAppStore()
    appStore.isLoading = false
}, 650)

// Loader stop.
axios.interceptors.response.use((config) => {
        stopLoader()
        return config
    }, (error) => {
        stopLoader()
        return Promise.reject(error);
    }
);

// Query params.
axios.interceptors.request.use(
    async function (config) {
        if (config.params) {
            config.params = query_params.translate(config.params)
        }
        return config
    },
    function (error) {
        return Promise.reject(error);
    },
);

// Access Token
axios.interceptors.request.use(
    async function (config) {
        const userStore = useUserStore()
        if (!config.headers) return config;
        if (userStore.accessToken) {
            config.headers['Authorization'] = `Bearer ${userStore.accessToken}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

/**
 * TODO :
 * - Traiter et formatter les erreurs API
 * - Auto refresh token
 */

export default axios;