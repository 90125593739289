<template>
    <div class="page-default page--orders">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Commandes" :showQuota="false" :showMenu="false" :showTpe="false" />
            <div class="content_inner">
                <div class="tabs">
                    <a @click="isProduct = false" class="tab" :class="{active: !isProduct}">Billetterie</a>
                    <a @click="isProduct = true" class="tab" :class="{active: isProduct}">Buvette</a>
                </div>

                <div class="searchForm">
                    <FormKit
                        type="text"
                        name="search"
                        v-model="searchString"
                        placeholder="Entrez un nom, numéro de commande..."
                    />
                    <FormKit
                        type="select"
                        name="sessionId"
                        placeholder="Choisissez une session de vente"
                        v-model="sessionId"
                        @change="updateSession"
                        :value="'all'"
                        :options="sessionsList"
                    />
                    <div class="count">
                        <template v-if="isProduct">
                            <p><span>{{ orderStore.count.productOrders }}</span> commande(s)</p>
                            <p><span>{{ orderStore.count.products }}</span> produits(s)</p>
                        </template>
                        <template v-else>
                            <p><span>{{ orderStore.count.ticketOrders }}</span> commande(s)</p>
                            <p><span>{{ orderStore.count.tickets }}</span> billet(s)</p>
                        </template>
                    </div>
                </div>

                <div class="table_result">
                    <MTable
                        @clicked="onClickOrder"
                        v-if="tableData.length"
                        :showLabels="true"
                        :rows="tableData"
                        :selectable="true"
                        :labels="tableLabel"
                    />

                    <div class="no-result" v-else>
                        {{ $t('generic.noresult') }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import { useOrderStore } from '@/stores/OrderStore';
    import { useSellingSessionStore } from '@/stores/SellingSessionStore';
    import { ref, computed, watch } from 'vue';
    import { onMounted } from 'vue';
    import MTable from '@/components/molecules/Table.vue'
    import { AppPrice } from '@/tools/Price';
    import shoetest from 'shoetest'
    import { useRoute, useRouter } from 'vue-router';

    const sellingSessionStore = useSellingSessionStore()
    const orderStore = useOrderStore()
    const sessionId = ref(null)
    const searchString = ref(null)

    const router = useRouter()
    const route = useRoute()

    const queryStringIsProduct = (route.query.isProduct && route.query.isProduct === '1') ? true : false
    const isProduct = ref<boolean>(queryStringIsProduct)


    onMounted(async () => {
        await sellingSessionStore.fetchAll()
        await fetchData()
    })

    const fetchData = (async () => {
        const idSession = (sessionId.value) ? sessionId.value : 'all'
        await orderStore.fetchAllOrders(sessionId.value, isProduct.value)
        await orderStore.fetchCount(idSession)
    })

    /**
     * @TODO : Refacto la partie date.
     */
    const formatDate = ((dateTime) => {
        let dateString = ''
        const date = new Date(dateTime)
        dateString += date.toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        dateString += ' à '
        dateString += date.toLocaleTimeString('fr', {
            hour: '2-digit',
            minute: '2-digit'
        })
        return dateString
    })

    const onClickOrder = ((row) => {
        router.push({name: 'order_details', params: { orderId: row.$id }})
    })

    const sessionsList = computed(() => {
        const sellingSessions = sellingSessionStore.sellingSessions.map(sellingSession => {
            let label = `${sellingSession.author.firstName} ${sellingSession.author.lastName} - ${formatDate(sellingSession.createdAt)}`
            if (sellingSession.status == 'opened') {
                label += ' (en cours)'
            }
            return {
                label,
                value: sellingSession.id
            }
        })
        return [
            {
                label: "Toutes les sessions",
                value: null
            },
            ...sellingSessions
        ]
    })

    const tableLabel = computed(() => {
        const data = [
            {
                label: "Date",
                value: "date",
                align: "left"
            },
            {
                label: "Informations",
                value: "infos",
                align: "right"
            },
            {
                label: "Numéro",
                value: "number",
                align: "right"
            },
            {
                label: "Montant",
                value: "price",
                align: "right"
            }
        ]
        if (isProduct.value) {
            data.push({
                label: "Emplacement",
                value: "zone",
                align: "right"
            })
        }
        return data
    })

    const getSearchableOrderInfos = ((order) => {
        const infos = []
        if (order && order.invoiceData) {
            if (order.invoiceData.firstName) {
                infos.push(order.invoiceData.firstName)
            }
            if (order.invoiceData.lastName) {
                infos.push(order.invoiceData.lastName)
            }
            if (order.invoiceData.company) {
                infos.push(order.invoiceData.company)
            }
            if (order.orderNumber) {
                infos.push(order.orderNumber)
            }
        }
        return infos.join(' ')
    })

    const getLabelOrderInfos = ((order) => {
        const infos = []
        if (order && order.invoiceData) {
            if (order.invoiceData.firstName) {
                infos.push(order.invoiceData.firstName)
            }
            if (order.invoiceData.lastName) {
                infos.push(order.invoiceData.lastName)
            }
            if (infos.length == 0 && order.invoiceData.email) {
                infos.push(order.invoiceData.email)
            }
            if (order.invoiceData.company) {
                infos.push(`(${order.invoiceData.company})`)
            }
        }
        return (infos.length) ? infos.join(' ') : '- Anonyme -'
    })

    const filteredOrders = computed(() => {
        return orderStore.orders.filter(order => {
            if (searchString.value) {
                const searchable = getSearchableOrderInfos(order)
                return shoetest.test(searchString.value.trim(), searchable)
            }
            return true
        })
    })

    const tableData = computed(() => {
        return filteredOrders.value.map(order => {
            const data = {
                $id: order.id,
                date: formatDate(order.createdAt),
                infos: getLabelOrderInfos(order),
                number: order.orderNumber,
                price: (order.totalPrice) ? new AppPrice(order.totalPrice.sellingPrice).divide100() : '--'
            }
            if (isProduct.value) {
                data['zone'] = (order.invoiceData) ? order.invoiceData.zone : '--'
            }
            return data
        })
    })

    watch(isProduct, async (value, oldValue) => {
        await fetchData()
    })

    const updateSession = (async () => {
        await fetchData()
    })


</script>