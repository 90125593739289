<template>
    <div @click="addToCart" class="card card__ticket_type" :class="{disabled: ticketType.isDisabled, 'on-error': onError}">
        <div class="top">
            <h3 class="card--title">{{ ticketType.name }}</h3>
        </div>
        <div class="bottom">
            <div class="price">
                {{ formattedPrice }}
            </div>
            <div v-if="ticketType.quota && !eventStore.isRoomPlacement" class="stock">
                <IconSvg class="icon" width="20" :name="'ticket'" />
                <span>{{ ticketType.currentStock }}</span>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { computed, ref } from "vue"
    import { useI18n } from "vue-i18n";
    import { useCartStore } from '@/stores/CartStore'
    import { TicketType } from "@/interfaces/TicketType";
    import { AppPrice } from '@/tools/Price'
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { TicketTypeCategory } from "@/interfaces/TicketTypeCategory";
    import { useEventStore } from "@/stores/EventStore";
    interface Props {
        ticketType: TicketType
        category: TicketTypeCategory
    }

    const onError = ref(false)
    const { t } = useI18n();
    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const props = withDefaults(defineProps<Props>(), {})

    const formattedPrice = computed(() => {
        const price = Number(props.ticketType.price)
        return new AppPrice(price).divide100().formatted
    })

    const minimumSelectable = computed(() => {
        let amount = 1
        if (props.ticketType.tickets?.minimumSelectable) {
            amount = props.ticketType.tickets?.minimumSelectable
        }
        return amount
    })

    const addToCart = () => {
        if (!props.ticketType.isDisabled) {
            for (let index = 0; index < minimumSelectable.value; index++) {
                cartStore.addTicket(props.ticketType)
            }
        }
        else {
            onError.value = true
            setTimeout(() => {
                onError.value = false
            }, 1000);
        }
    }

</script>