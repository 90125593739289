import { APIListResponse, APIResponse, APISingleResponse, GetParams } from "@/interfaces/Types.definition";
import axios from "@/tools/Axios/axios";
import * as Sentry from "@sentry/browser";

export const getAllItemsAction = async <T, EntityStore>(url: string, params: any, store: EntityStore, stateName: string) => {
    let page = (params.page) ?? 1
    try {
        const { data, status }: APIListResponse<T> = await axios.get(url, {params});

        if (status == 204) {
            store[stateName] = []
        }
        if (data) {
            if (page === 1) {
                store[stateName] = data.data
            }
            else {
                store[stateName] = store[stateName].concat(data.data)
            }
            if (data.pager.pages > page) {
                const newPage: number = page + 1
                params.page = newPage
                await getAllItemsAction(url, params, store, stateName)
            }
        }
    } catch (error) {
        Sentry.captureException(error)
        return false
    }
}

export const getItemAction = async <T, EntityStore>(url: string, params: any, store: EntityStore, stateName: string) => {
    try {
        const { data }: APISingleResponse<T> = await axios.get(url, {params});
        store[stateName] = data
    } catch (error) {
        Sentry.captureException(error)
        return false
    }
}
