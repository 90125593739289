import { CartItem, CartTicket } from "@/interfaces/CartItem";
import { Order, OrderStatusTypeEnum, PaymentMethodTypeEnum } from "@/interfaces/Order";
import { TicketType } from "@/interfaces/TicketType";
import { DefaultGetParams, GetParams, PriceType, payloadOrderInfosType } from "@/interfaces/Types.definition";
import axios from "@/tools/Axios/axios";
import { AppPrice } from "@/tools/Price";
import { RoomPlacement } from "@/tools/RoomPlacement/RoomPlacement";
import { Store, defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useEventStore } from "./EventStore";
import { useTicketTypeStore } from "./TicketTypeStore";
import { useUserStore } from "./UserStore";
import * as Sentry from "@sentry/browser";
import { Addon } from "@/interfaces/Addon";
import { IPaymentTransaction, IPaymentMethod } from "@/interfaces/PaymentTransaction";
import { getItemAction } from "./common/actions";
import { saveAs } from 'file-saver';
import { useTpeStore } from "./TpeStore";
import { useOrderStore } from "./OrderStore";

export interface CartState {
    order: Order | null,
    isProduct: boolean,
    currentStep: string,
    items: CartItem[],
    promocode: string | null,
    paymentMethod: IPaymentMethod | null,
    paymentTransactions: IPaymentTransaction[]
}

export const useCartStore = defineStore("CartStore", {
    state: (): CartState => {
        return {
            isProduct: false,
            order: null,
            currentStep: 'tickets',
            items: [],
            promocode: null,
            paymentTransactions: [],
            paymentMethod: null
        }
    },
    actions: {
        async fetchOrder(orderId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['createdAt', 'eventId', 'name', 'token', 'isProduct', 'payment', 'orderNumber', 'status', 'updatedAt', 'invoiceData', 'totalPrice', 'ticketOfficeSessionId']
            await getItemAction<Event, Store<'CartStore'>>(`/events/orders/${orderId}`, params, this, 'order')
        },
        nextStep() {
            const eventId = useEventStore().currentEvent.id
            if (eventId) {
                switch (this.$router.currentRoute.value.name) {
                    case 'ticket':
                    case 'ticket_seat':
                        this.$router.push({name: 'infos', params: { eventId }})
                        break;
                    case 'buvette':
                        this.$router.push({name: 'buvette_infos'})
                        break;
                    case 'buvette_infos':
                        this.$router.push({name: 'payment', params: { eventId }})
                        break;
                    case 'infos':
                        this.$router.push({name: 'payment', params: { eventId }})
                        break;
                    case 'payment':
                        const orderId = this.order.id
                        if (orderId) {
                            this.$router.push({name: 'summary', params: { eventId, orderId }})
                        }
                        break
                }
            }
        },
        async cancel() {
            if (this.order && this.order.isProduct === false && this.order.status != OrderStatusTypeEnum.PAID) {
                await this.releaseCurrentsSeats()
            }
            if (this.order) {
                // @TODO: Supprimer commande draft via api
            }
            this.$reset()
        },
        /**
         *
         * @param ticketType
         */
        addTicket(ticketType: TicketType) {
            const tempId = Math.random().toString(36).slice(2, 7);
            const existingCartItem = this.items.find(item => item.ticketType.id == ticketType.id)
            const cartTicket: CartTicket = {
                label: ticketType.name,
                _app: {
                    tempId,
                    forms: [],
                    addons: []
                }
            }
            if (existingCartItem) {
                existingCartItem.quantity += 1
                existingCartItem.tickets.push(cartTicket)
            }
            else {
                const newCartItem: CartItem = {
                    ticketType,
                    quantity: 1,
                    tickets: [cartTicket]
                }
                this.items.push(newCartItem)
            }
        },
        removeTicket(ticketType: TicketType) {
            const cartItemIndex = this.items.findIndex(item => item.ticketType.id == ticketType.id)
            if (cartItemIndex > -1) {
                const cartItem = this.items[cartItemIndex]
                if (cartItem.quantity == 1) {
                    this.items = this.items.filter(el => el.ticketType.id != ticketType.id)
                }
                else {
                    this.items[cartItemIndex].quantity -= 1
                    this.items[cartItemIndex].tickets.pop()

                }
            }
        },
        async deleteItem(ticketType: TicketType) {
            const cartItemIndex = this.items.findIndex(item => item.ticketType.id == ticketType.id)
            if (cartItemIndex > -1) {
                if (useEventStore().isRoomPlacement) {
                    const seats = this.items[cartItemIndex].tickets.map(ticket => {
                        if (ticket.seat) {
                            return ticket.seat[0]
                        }
                        return ''
                    }).filter(ticket => (ticket != ''))
                    if (seats.length) {
                        try {
                            await new RoomPlacement().releaseSeats(seats)
                        } catch (error: any) {
                            Sentry.captureException(error, { extra: error.cause })
                            toast.error('Une erreur est survenue lors de la libération des places')
                        }
                    }
                }
                this.items = this.items.filter(el => el.ticketType.id != ticketType.id)
            }
        },
        getItemPerTicketType(ticketType: TicketType): CartItem | undefined {
            return this.items.find((el: CartItem) => {
                return el.ticketType.id == ticketType.id
            })
        },
        async generatePdf(download: boolean = false): Promise<any> {
            try {
                let url: string
                let filename = `billets-${this.order.orderNumber}.pdf`
                const tickets = useOrderStore().tickets
                if (this.order.isProduct && tickets.length) {
                    url = `/events/orders/stream/ticketId/${tickets[0].id}/${this.order.token}`
                    filename = `commande-${this.order.orderNumber}.pdf`
                }
                else {
                    url = `/events/orders/stream/orderIdTickets/${this.order.id}/${this.order.token}`
                }

                const pdf = await axios.get(url, {
                    responseType: 'blob'
                })

                if (download) {
                   saveAs(pdf.data, filename);
                }
                return pdf
            } catch (error) {
                Sentry.captureException(error)
                toast.error('Erreur lors de la généreration de votre PDF')
                return false
            }
        },
        async generateDraftOrder(isProduct: boolean = false): Promise<boolean> {
            const userStore = useUserStore()
            const eventStore = useEventStore()
            if (userStore.sellingSession) {
                const tickets = this.items.map(cartItem => {
                    return {
                        quantity: cartItem.quantity,
                        sessionId: (eventStore.currentSession) ? eventStore.currentSession.id : null,
                        ticketTypeId: cartItem.ticketType.id,
                        seat: cartItem.tickets.map(ticket => (Array.isArray(ticket.seat)) ? (ticket.seat)[0]: null)
                    }
                })
                const url = `/events/orders/draft/${userStore.sellingSession.eventId}/ticket-office/${userStore.sellingSession.id}`
                try {
                    const { data, status } = await axios.post(url, {
                        currency: eventStore.currentEvent.ticketing.defaultCurrencyId,
                        origin: 'ticketOffice',
                        isProduct,
                        promoCode: null,
                        tickets
                    })
                    if (status == 201) {
                        this.order = data
                        this.order._app = {
                            paymentMethods: []
                        }
                        return true
                    }
                    toast.error('Une erreur est survenue lors de la création de la commande')
                } catch (error: any) {
                    Sentry.captureException(error)
                    toast.error('Une erreur est survenue lors de la création de la commande')
                }
            }
            return false
        },
        async updateOrderInfos(payload: payloadOrderInfosType): Promise<boolean> {
            if (this.order) {
                payload.sendingMethod = 'same'
                const url = `/events/orders/draft/${this.order.token}`
                if (payload.tickets) {
                    payload.tickets = payload.tickets.map((ticketPayload) => {
                        const cartTicket = this.getCartTicketByTempid(ticketPayload.tempId)
                        if (cartTicket) {
                            ticketPayload.addons = cartTicket._app.addons.map((addon: Addon) => addon.id)
                        }
                        return ticketPayload
                    })
                }

                try {
                    const { status } = await axios.put(url, {...payload})
                    if (status == 200) {
                        return true
                    }
                } catch (error: any) {
                    Sentry.captureException(error, { extra: {
                        apiRequest: JSON.stringify(payload),
                        apiResponse: JSON.stringify(error)
                    }})
                    toast.error('Une erreur est survenue')
                }
            }
            return false
        },
        async payOrderWithTpe(): Promise<boolean> {
            try {
                const total = this.totalCart.amount * 100
                const resultIntent = await useTpeStore().getPaymentIntent(this.order.token, total)
                if (resultIntent) {
                    const resultCollect = await useTpeStore().collectPayment()
                    return resultCollect
                }
            } catch (error) {
                toast.error('Une erreur est survenue lors du paiement')
                Sentry.captureException(error)
                return false
            }
            return true
        },

        async payOrder(): Promise<boolean> {
            if (this.paymentMethod) {
                if (this.paymentMethod.method == PaymentMethodTypeEnum.creditCardTerminal) {
                    return this.payOrderWithTpe()
                }
                try {
                    let url = `/events/orders/proceed-payment/${this.order.token}/${this.paymentMethod.method}`
                    if (this.paymentMethod.method == PaymentMethodTypeEnum.custom) {
                        url += `/${this.paymentMethod.customMethod}`
                    }
                    await axios.get(url)
                    return true
                } catch (error) {
                    Sentry.captureException(error, { extra: error.cause })
                    toast.error('Une erreur est survenue')
                    return false
                }

            }
            return false
        },
        getCartTicketByTempid(tempId: string): CartTicket|false {
            let searchedCartTicket:CartTicket|false = false
            if (this.items) {
                this.items.forEach((cartItem: CartItem) => {
                    const found = cartItem.tickets.find((cartTicket: CartTicket) => cartTicket._app.tempId == tempId)
                    if (found) {
                        searchedCartTicket = found
                    }
                })
            }
            return searchedCartTicket
        },
        async releaseCurrentsSeats(): Promise<boolean> {
            if (useEventStore().isRoomPlacement) {
                const secret = useUserStore().organisation?.seatsIoWorkspaceSecret
                if (secret) {
                    const eventId = useEventStore().roomPlacementEventId
                    if (eventId) {
                        try {
                            return await new RoomPlacement().releaseAllSeats(this.items)
                        } catch (error: any) {
                            Sentry.captureException(error, { extra: error.cause })
                            toast.error('Une erreur est survenue lors de la libération des places')
                            return false
                        }
                    }
                }
            }
            return false
        },
        async holdAvailablePlaces(): Promise<boolean> {
            const secret = useUserStore().organisation?.seatsIoWorkspaceSecret
            if (secret && useEventStore().isRoomPlacement) {
                const eventId = useEventStore().roomPlacementEventId
                if (eventId) {
                    try {
                        return await new RoomPlacement().holdSeats(this.items, useTicketTypeStore().categories)
                    } catch (error: any) {
                        Sentry.captureException(error, { extra: error.cause })
                        toast.error("Problème avec le placement automatique. Veuillez utiliser le placement manuel")
                        await this.releaseCurrentsSeats()
                        return false
                    }
                }
            }
            return false
        }
    },
    getters: {
        itemsQty(): number {
            return this.items.reduce((acc, item: CartItem) => {
                return acc + item.tickets.length
            }, 0)
        },
        isEmpty():boolean {
            return (this.items.length == 0)
        },
        totalFees(): PriceType|false {
            const ticketOfficeOptions = useEventStore().currentEvent.ticketOffice
            const commissionIncreased = useEventStore().currentEvent.commissionIncreased
            let hasFees = false
            if (
                commissionIncreased &&
                (
                    this.isProduct && ticketOfficeOptions.includeProductCommission === true ||
                    !this.isProduct && ticketOfficeOptions.includeTicketCommission === true
                )
            ) {
                hasFees = true
            }

            const commission = useEventStore().currentEvent.commission
            let fees = 0
            if (hasFees) {
                this.items.forEach((cartItem: CartItem) => {
                    cartItem.tickets.forEach((cartTicket: CartTicket) => {
                        let ticketPrice = cartItem.ticketType.price
                        if (cartTicket._app.addons) {
                            cartTicket._app.addons.forEach((addon: Addon) => {
                                ticketPrice += addon.price
                            })
                        }
                        if (ticketPrice > 0) {
                            fees += Number(((ticketPrice * commission.percentage / 100) + commission.flat).toFixed(0))
                        }
                    })
                })
            }
            return new AppPrice(fees).divide100()
        },
        totalCartWithFees() {
            if (this.totalFees) {
                return new AppPrice(this.totalFees.amount + this.totalCart.amount)
            }
            return this.totalCart
        },
        totalCart(): PriceType {
            const totalTicket: number = this.items.reduce((acc, item: CartItem) => acc + (item.quantity * Number(item.ticketType.price)), 0)
            let totalAddons = 0
            this.items.forEach((cartItem: CartItem) => {
                cartItem.tickets.forEach((cartTicket: CartTicket) => {
                    if (cartTicket._app.addons) {
                        cartTicket._app.addons.forEach((addon: Addon) => {
                            totalAddons += addon.price
                        })
                    }
                })
            })
            const total = totalTicket + totalAddons
            return new AppPrice(total).divide100()
        },
        totalPaid(): PriceType {
            let amount = 0
            if (this.paymentMethod) {
                amount = this.totalCart.amount * 100
            }
            return new AppPrice(amount).divide100()
        },
        leftToPay(): PriceType {
            const amount = this.totalCart.amount - this.totalPaid.amount
            return new AppPrice(amount)
        },
        isOrderExpired(): boolean {
            if (this.order) {
                const expire = new Date(this.order.expire).getTime();
				const now = new Date().getTime();
                if (expire > now) {
                    return false
                }
            }
            return false
        }
    },

})
