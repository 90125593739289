<template>
    <div v-if="category" @click="clicked" class="product_category" :class="currentClasses">
        <p class="product_category__title">
            {{ category.name }}
        </p>
    </div>
    <slot />
</template>
<script setup lang="ts">
    import { TicketTypeCategory } from '@/interfaces/TicketTypeCategory'
    import { computed } from 'vue'
    interface Props {
        category: TicketTypeCategory
        isActive: boolean
    }

    const emit = defineEmits(['click'])
    const props = withDefaults(defineProps<Props>(), {
        isActive: false
    })

    const clicked = (() => {
        if (!isDisabled.value) {
            emit('click', {
                category: props.category
            })
        }
    })

    const isDisabled = computed(() => {
        if (props.category.quota && props.category.currentStock <= 0) {
            return true
        }
    })

    const currentClasses = computed(() => {
        const classes = []
        if (props.isActive) {
            classes.push('active')
        }
        if (isDisabled.value) {
            classes.push('disabled')
        }
        return classes
    })
</script>
