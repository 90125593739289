import axios from "@/tools/Axios/axios";
import { defineStore } from "pinia";
import * as Sentry from "@sentry/browser";
import { toast } from "vue3-toastify";
import { IStripePaymentIntent } from "@/interfaces/IPaymentIntent";
import { IStripeReader } from "@/interfaces/IStripeReader";

export interface TpeState {
    payment_intent: IStripePaymentIntent | null
    collectInProgress: boolean
    connection_token: string | null
    terminal: object | null,
    readers: IStripeReader[] | null
    reader: IStripeReader | null
}

export const useTpeStore = defineStore("TpeStore", {
    state: (): TpeState => {
        return {
            payment_intent: null,
            collectInProgress: false,
            connection_token: null,
            terminal: null,
            readers: null,
            reader: null
        }
    },
    actions: {
        async initTerminal() {
            const browserWindow: any = window
            this.terminal = browserWindow.StripeTerminal.create({
                onFetchConnectionToken: this.getConnectionToken,
                onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
            })
        },
        async discoverReaders() {
            const config = { simulated: false }
            const discoverResult = await this.terminal.discoverReaders(config);
            if (discoverResult.error) {
                toast.error("Impossible de récupérer la liste des TPE")
            }
            else if (discoverResult.discoveredReaders.length === 0) {
                toast.error('Aucun TPE disponibles.');
            }
            else {
                this.readers = discoverResult.discoveredReaders
            }
        },
        async connectReader(reader: IStripeReader): Promise<boolean> {
            const connectedReader = await this.terminal.connectReader(reader)
            if (connectedReader.error) {
                console.log(connectedReader);

                toast.error('Impossible de connecter le TPE')
                return false
            }
            else if (connectedReader.reader) {
                this.reader = reader
                localStorage.setItem('tpe_reader', JSON.stringify(reader))
                toast(`TPE "${reader.label}" connecté`)
                return true
            }
            return false
        },
        async disconnectReader(reader: IStripeReader) {
            try {
                await this.terminal.disconnectReader(reader)
                this.reader = null
                localStorage.removeItem('tpe_reader')
                toast(`TPE "${reader.label}" déconnecté`)

            } catch (error: any) {
                toast.error("Impossible de déconnecter le TPE")
            }
        },
        async collectPayment(): Promise<boolean> {
            try {
                const connectionStatus: 'connected'|'connecting'|'not_connected' = await this.terminal.getConnectionStatus()
                if (connectionStatus == 'connecting') {
                    toast.error('Le TPE est en cours de connexion')
                    return false
                }
                if (connectionStatus == 'not_connected') {
                    await this.reconnectReader()
                    if (!this.reader) {
                        return false
                    }
                }
                this.collectInProgress = true
                const resultCollect = await this.terminal.collectPaymentMethod(this.payment_intent.client_secret, {
                    config_override: {
                        update_payment_intent: true,
                        payment_intent_id: this.payment_intent.id
                    }
                });
                if (resultCollect.error) {
                    if (resultCollect.error.code == 'canceled') {
                        toast.error("Paiement annulé")
                    }
                    else {
                        toast.error("Problème lors du paiement")
                    }
                }
                else {
                    const resultProcess = await this.terminal.processPayment(resultCollect.paymentIntent);
                    if (resultProcess.error) {
                        toast.error('Problème lors du paiement')
                    } else if (resultProcess.paymentIntent) {
                        toast('Le paiement a bien été effectué')
                        this.collectInProgress = false
                        return true
                    }
                }
            } catch (error: any) {
                toast.error("Impossible de collecter le paiement")
                console.log(error);

            }
            this.collectInProgress = false
            return false
        },
        async cancelCollectPayment() {
            try {
                const result = await this.terminal.cancelCollectPaymentMethod()
                if (result.error) {
                    toast.error("Une erreur est survenue lors de l'annulation")
                }
            } catch (error: any) {
                console.log("====> ", error);
            }
            this.payment_intent = null
            this.collectInProgress = false
        },
        unexpectedDisconnect() {
            console.log("TPE Disconnect");
        },
        async getConnectionToken(): Promise<string> {
            const url = `/tpe/connection_token`
            try {
                const { data } = await axios.post(url)
                this.connection_token = data.token
                return data.token
            } catch (error: any) {
                Sentry.captureException(error, { extra: {
                    apiResponse: JSON.stringify(error)
                }})
                toast.error('Une erreur est survenue')
            }
        },
        async getPaymentIntent(token: string, amount: number): Promise<boolean> {
            const url = `/tpe/create_payment_intent`
            try {
                const { data } = await axios.post(url, {
                    token,
                    amount
                })
                this.payment_intent = data.paymentIntent
                return true
            } catch (error: any) {
                Sentry.captureException(error, { extra: {
                    apiResponse: JSON.stringify(error)
                }})
                toast.error('Une erreur est survenue')
            }
            return false
        },
        async reconnectReader(): Promise<boolean> {
            try {
                this.reader = null
                if (!this.terminal) {
                    await this.initTerminal()
                }
                const disconnectedReader = this.disconnectedReader
                if (disconnectedReader) {
                    toast('Reconnexion au TPE "' + disconnectedReader.label + '"...')
                    return await this.connectReader(disconnectedReader)
                }
                return false
            } catch (error) {
                Sentry.captureException(error)
                toast.error(error.message)
                return false
            }
        }
    },
    getters: {
        disconnectedReader(): IStripeReader|false {
            const reader = localStorage.getItem('tpe_reader')
            if (reader) {
                return JSON.parse(reader)
            }
            return false
        }
    }
})