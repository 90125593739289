import { defineStore, Store } from "pinia";
import { DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import { getAllItemsAction, getItemAction } from "./common/actions";
import { useEventStore as eventStore } from "./EventStore";
import printJS from 'print-js'

import * as Sentry from "@sentry/browser";
import { Order, OrderInvoiceData, OrderStatusTypeEnum } from "@/interfaces/Order";
import { ITicket } from "@/interfaces/ITicket";
import { useEventStore } from "./EventStore";
import axios from "@/tools/Axios/axios";
import { toast } from "vue3-toastify";

export interface OrderState {
    order: Order | null,
    orders: Order[] | null,
    ticket: ITicket | null,
    tickets: ITicket[] | null,
    count: {
        ticketOrders: number,
        productOrders: number,
        tickets: number,
        products: number,
    }
}

export const useOrderStore = defineStore("OrderStore", {
    state: (): OrderState => {
        return {
            order: null,
            orders: [],
            ticket: null,
            tickets: [],
            count: {
                ticketOrders: 0,
                productOrders: 0,
                tickets: 0,
                products: 0,
            }
        }
    },
    actions: {
        async fetchCount(sessionId: string = 'all') {
            try {
                const { data } = await axios.get(`/ticket-offices/selling-sessions/eventId/${eventStore().currentEvent?.id}/${sessionId}`)
                this.count.ticketOrders = data.orderCount
                this.count.productOrders = data.orderProductCount
                this.count.tickets = data.ticketCount
                this.count.products = data.productCount
            } catch (error) {
                Sentry.captureException(error)
                toast.error(error.message)
            }
        },
        async fetchOrder(orderId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['createdAt', 'eventId', 'name', 'token', 'isProduct', 'payment', 'orderNumber', 'status', 'updatedAt', 'invoiceData', 'totalPrice', 'ticketOfficeSessionId']
            params.populate = ['ticketOfficeSession']
            await getItemAction<Order, Store<'OrderStore'>>(`/events/orders/${orderId}`, params, this, 'order')
        },
        async fetchAllOrders(sellingSession: string = null, isProduct: boolean = null) {
            this.orders = []
            let params: GetParams = DefaultGetParams()
            params.select = ['createdAt', 'eventId', 'name', 'token', 'isProduct', 'payment', 'orderNumber', 'status', 'updatedAt', 'invoiceData', 'totalPrice', 'ticketOfficeSessionId']
            params.filter = {
                status: [
                    OrderStatusTypeEnum.PAID
                ],
                eventId: eventStore().currentEvent.id,
                origin: 'ticketOffice'
            }
            if (isProduct !== null) {
                params.filter['isProduct'] = isProduct
            }
            if (sellingSession) {
                params.filter['ticketOfficeSessionId'] = sellingSession
            }
            params.sort = [['createdAt', -1]]

            await getAllItemsAction<Order, Store<'OrderStore'>>(`/events/orders/list`, params, this, 'orders')
        },
        async fetchTicketsByOrder(order: Order) {
            const currentEvent = useEventStore().currentEvent
            let params: GetParams = DefaultGetParams()
            params.select = ['createdAt', 'eventId', 'name', 'isProduct', 'categoryName', 'firstName', 'lastName', 'ticketNumber', 'price', 'status', 'updatedAt']
            params.filter = {
                orderId: order.id,
                isProduct: order.isProduct,
                eventId: currentEvent.id
            }
            await getAllItemsAction<ITicket, Store<'OrderStore'>>(`/events/tickets/list`, params, this, 'tickets')
        },
        async sendTicketsToMail(order: Order, email:string) {
            const url = `/events/orders/send-tickets-from-mail/${order.id}`
            try {
                const { status } = await axios.post(url, { email })
                toast('Les billets de la commande ont bien été envoyés')
                return true
            } catch (error) {
                Sentry.captureException(error, { extra: {
                    apiRequest: JSON.stringify({email}),
                    apiResponse: JSON.stringify(error)
                }})
                toast.error("Une erreur est survenue")
                toast.error(error.message)
                return false
            }
        },
        async sendInvoice(order: Order, invoiceData: OrderInvoiceData): Promise<boolean> {
            const url = `/events/orders/send-invoice/${order.id}`
            try {
                const { status } = await axios.put(url, invoiceData)
                toast('La facture a bien été envoyée')
                return true
            } catch (error) {
                Sentry.captureException(error, { extra: {
                    apiRequest: JSON.stringify(invoiceData),
                    apiResponse: JSON.stringify(error)
                }})
                toast.error("Une erreur est survenue")
                toast.error(error.message)
                return false
            }
        },
        printOrder(order: Order, tickets: ITicket[]= null) {
            const pdfTemplate = (eventStore().currentEvent.ticketing.hasBadge && !order.isProduct) ? 'badge' : 'ticket'
            let printable = `${import.meta.env.VITE_API_BASE_URL}/events/orders/stream/orderIdTickets/${order.id}/${order.token}?pdfTemplate=${pdfTemplate}`
            if (order.isProduct && tickets.length) {
                const ticket = tickets[0]
                printable = `${import.meta.env.VITE_API_BASE_URL}/events/orders/stream/ticketId/${ticket.id}/${order.token}`
            }
            printJS({
                printable,
                showModal: true,
                modalMessage: 'Chargement du document...'
            })
        },
        printTicket(order: Order, ticket: ITicket) {
            const pdfTemplate = (eventStore().currentEvent.ticketing.hasBadge) ? 'badge' : 'ticket'
            const printable = `${import.meta.env.VITE_API_BASE_URL}/events/orders/stream/ticketId/${ticket.id}/${order.token}?pdfTemplate=${pdfTemplate}`
            printJS({
                printable,
                showModal: true,
                modalMessage: 'Chargement du billet...'
            })
        }
    }
})