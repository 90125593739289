<template>
    <div class="page-resume-session">
        <div class="container container--small">
            <div class="resume">
                <h1 class="title--h1 title--separator">
                    {{ $t('pages.resume.title') }}
                </h1>
                <div class="description">
                    <p>{{ $t('pages.resume.description', {date: dateSessionString}) }}</p>
                </div>
                <div class="button">
                    <Button :loading="loading" @click="resumeSession">
                        {{ $t('pages.resume.btnLabel') }}
                    </Button>
                </div>
            </div>
            <SessionLogoutVue />
        </div>
    </div>
</template>
<script setup lang="ts">
    import Button from '@/components/atoms/Button.vue'
    import SessionLogoutVue from '@/components/atoms/SessionLogout.vue'
    import { useAppStore } from '@/stores/AppStore'
    import { useEventStore } from '@/stores/EventStore'
    import { useUserStore } from '@/stores/UserStore'
    import { computed, ref } from 'vue'
    import { useVfm } from 'vue-final-modal'
    import { useRouter } from 'vue-router'
    const userStore = useUserStore()
    const vfm = useVfm()
    const appStore = useAppStore()

    const eventStore = useEventStore()
    const router = useRouter()
    const loading = ref(false)
    const dateSessionString = computed(() => {
        let dateString = ''
        if (userStore.sellingSession) {
            const date = new Date(userStore.sellingSession?.createdAt)
            dateString += date.toLocaleDateString('fr', {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            })
            dateString += ' à '
            dateString += date.toLocaleTimeString('fr', {
                hour: '2-digit',
                minute: '2-digit'
            })
        }
        return dateString
    })

    const resumeSession = async () => {
        userStore.sessionStarted = true
        const eventId = userStore.sellingSession?.eventId
        if (eventId) {
            loading.value = true
            await eventStore.retrieveEvent(eventId)
            router.push({name: 'buvette'});
        }
    }
</script>