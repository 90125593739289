<template>
    <div class="page-organisations">
        <div class="container">
            <div class="inner">
                <h1 class="title title--h1 title--separator">{{ $t('auth.organisationPage.title') }}</h1>
                <div v-if="userStore.organisations && userStore.organisations.length > 6" class="filter-orga">
                    <FormKit
                        type="text"
                        name="filter"
                        v-model="filterText"
                        :placeholder="$t('auth.organisationPage.filterPlaceHolder')"
                    />
                </div>
                <div v-if="filteredOrgas.length" class="list-orga">
                    <CardOrganisationVue @clicked="selectOrganisation" :organisation="organisation" v-for="organisation in filteredOrgas" :key="organisation.id" />
                </div>
                <div v-else class="empty-orgas">
                    <h2 class="title-h2">
                        Aucun résultat 😢
                    </h2>
                </div>
            </div>
            <SessionLogoutVue :logout="true" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import CardOrganisationVue from '@/components/molecules/CardOrganisation.vue'
    import { useUserStore } from '@/stores/UserStore'
    import { computed, onMounted, ref } from 'vue'
    import { Organisation } from '@/interfaces/Organisation'
    import { useRouter } from 'vue-router';
    import { toast } from 'vue3-toastify'
    import shoetest from 'shoetest'
    import SessionLogoutVue from '@/components/atoms/SessionLogout.vue'

    const router = useRouter()
    const userStore = useUserStore()
    const filterText = ref(null)

    onMounted(async () => {
        if (userStore.organisations) {
            if (userStore.organisations.length == 1) {
                await userStore.setOrganisation(userStore.organisations[0])
                router.push({name: 'events', params: { organisationId: userStore.organisations[0].id }})
            }
        }
    })

    const selectOrganisation = async (organisation: Organisation) => {
        try {
            await userStore.setOrganisation(organisation)
            router.push({name: 'events', params: { organisationId: organisation.id }})
        } catch (error) {
            toast.error('Impossible de récupérer les informations de votre organisation')
        }
    }

    const filteredOrgas = computed(() => {
        if (!userStore.organisations) {
            return []
        }
        return userStore.organisations?.filter(el => {
            if (!filterText.value) {
                return true
            }
            return shoetest.test(filterText.value.trim(), el.name)
        })
    })
</script>