<template>
    <div class="page-full page-summary">
        <div class="content">
            <div class="content_inner">
                <div class="head">
                    <IconSvg class="icon" :name="'check'" />
                    <h1 class="title title--h1">
                        {{ $t('summary.title') }}
                    </h1>
                </div>
                <MTable :showLabels="false" :rows="tableData" :labels="tableLabel" />
                <div class="actions">
                    <Button @click="newOrder()" icon="ticket" size="big">
                        {{ $t('summary.new_order') }}
                    </Button>
                    <div class="secondary">
                        <Button @click="showOrder" icon="show" variant="secondary">
                            {{ $t('summary.show_order') }}
                        </Button>
                        <Button @click="printOrder" icon="printer" variant="secondary">
                            {{ $t('summary.print_order') }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import IconSvg from '@/components/atoms/ASvg.vue'
    import MTable from '@/components/molecules/Table.vue'
    import Button from '@/components/atoms/Button.vue'
    import { ref, onMounted, computed } from 'vue';
    import { useCartStore } from '@/stores/CartStore';
    import { AppPrice } from '@/tools/Price';
    import { PaymentMethodEnum, PaymentMethodTypeEnum } from '@/interfaces/Order';
    import { useRouter } from 'vue-router';
    import { useEventStore } from '@/stores/EventStore';
    import { useAppStore } from '@/stores/AppStore';
    import { useOrderStore } from '@/stores/OrderStore';

    const eventStore = useEventStore()
    const router = useRouter()
    const appStore = useAppStore()
    const cartStore = useCartStore()
    const orderStore = useOrderStore()
    const orderData = ref({
        number: '',
        price: '',
        method: '',
        customMethod: ''
    })

    onMounted(async () => {
        appStore.isLoading = true
        // Attente du webhook pour afficher les informations post-paiement.
        if (cartStore.paymentMethod.method == PaymentMethodTypeEnum.creditCardTerminal) {
            await sleep(1000)
        }

        await cartStore.fetchOrder(cartStore.order.id)
        await orderStore.fetchTicketsByOrder(cartStore.order)

        orderData.value = {
            number: cartStore.order.orderNumber,
            price: new AppPrice(cartStore.order.totalPrice.sellingPrice).divide100().toString(),
            method: cartStore.order.payment.method,
            customMethod: (cartStore.order.payment.customMethod) ? cartStore.order.payment.customMethod.label : null
        }
        appStore.isLoading = false
    })

    const sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    const showOrder = (() => {
        router.push({name: 'order_details', params: { orderId: cartStore.order.id }})
    })

    const printOrder = (() => {
        orderStore.printOrder(cartStore.order, orderStore.tickets)
    })

    const newOrder = async () => {
        const eventId = eventStore.currentEvent.id
        if (cartStore.order.isProduct) {
            router.push({name: 'buvette'})
        }
        else {
            router.push({name: 'ticket', params: { eventId }})
        }
    }

    const tableLabel = computed(() => {
        return [
            {
                label: "Libellé",
                value: "label",
                align: "left"
            },
            {
                label: "Valeur",
                value: "value",
                align: "right"
            }
        ]
    })

    const itemLabel = (cartStore.order.isProduct) ? 'produits' : 'tickets'
    const tableData = computed(() => {
        return [
            {
                label: "Numéro de commande",
                value: orderData.value.number
            },
            {
                label: "Montant de la commande",
                value: orderData.value.price
            },
            {
                label: "Réglé par",
                value: (orderData.value.method == PaymentMethodTypeEnum.custom) ? orderData.value.customMethod : PaymentMethodEnum[orderData.value.method]
            },
            {
                label: `Nombre de ${itemLabel}`,
                value: orderStore.tickets.length
            },
        ]
    })

</script>