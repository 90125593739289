<template>
    <div @click="cardClicked(organisation)" class="card card__organisation">
        <div class="logo">
            <img :src="organisation.image?.url" :alt="organisation.name" width="150">
        </div>
        <h3 class="card--title">{{ organisation.name }}</h3>
        <p class="desc">{{ organisation.type }}</p>
    </div>
</template>
<script setup lang="ts">
    const emit = defineEmits(['clicked'])
    const props = defineProps({
        organisation: {type: Object, required: true},
    })
    const cardClicked = (organisation) => {
        emit('clicked', organisation);
    }
</script>