import { OrderInvoiceData } from "./Order";

export interface GetParams {
    template?: 'base' | 'full';
    select?: string[];
    filter?: object;
    populate?: string[];
    sort?: [string, number][];
    page?: number;
    limit?: number;
}

export const DefaultGetParams = () => ({
    select: [],
    filter: {},
    populate: [],
    page: 1
  })

export interface APIListResponse<T> extends APIResponse<T> {
    data: {
        data: T[],
        pager: {
            page: number,
            pages: number
        }
        total: number
    },
}

export interface APISingleResponse<T> extends APIResponse<T> {
    data: T,
}

export interface APIResponse<T> {
    status: number
}

export interface PriceType {
    amount: number
    formatted: string
    currency: string
}

type SeatsIoReportDetail = {
    byAvailability: any
    byObjectType: any
    bySection: any
    count: number
    byChannel: any
    byCategoryKey: any
    bySelectability: any
    byStatus: any
    byCategoryLabel: any
}

export type SeatsIoReport = {
    available: SeatsIoReportDetail
    booked: SeatsIoReportDetail
    disabled_by_social_distancing: SeatsIoReportDetail
    not_for_sale: SeatsIoReportDetail
    reservedByToken: SeatsIoReportDetail
}

export type GenerateDraftResponse = {

}

export type payloadOrderInfosType = {
    invoiceData?: OrderInvoiceData
    sendingMethod?: "same"
    tickets?: ticketDataType[]
}

type ticketDataType = {
    addons?: string[]
    form: formDataType[]
    ticketTypeId: string
    tempId: string
}

type formDataType = {
    id: string
    fields: fieldDataType[]
}

type fieldDataType = {
    formFieldId: string
    values: string | string[]
}
