import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useUserStore } from '@/stores/UserStore'

import LoginView from '@/views/LoginView.vue'
import OrganisationsView from '@/views/OrganisationsView.vue'
import EventsView from '@/views/EventsView.vue'
import TicketsView from '@/views/Guichet/TicketsView.vue'
import ProductsView from '@/views/Buvette/ProductsView.vue'
import TicketsSeatsView from '@/views/Guichet/TicketsSeatsView.vue'
import InfosView from '@/views/Guichet/InfosView.vue'
import ProductInfosView from '@/views/Buvette/InfosView.vue'
import SummaryView from '@/views/Guichet/SummaryView.vue'
import PaymentView from '@/views/Guichet/PaymentView.vue'
import SessionResumeView from '@/views/ResumeSession.vue'
import OrdersView from '@/views/Orders/OrdersView.vue'
import OrderDetailsView from '@/views/Orders/OrderDetailsView.vue'
import EventSessionsView from '@/views/EventSessionsView.vue'
import SettingsView from '@/views/Settings/Settings.vue'
import StatsView from '@/views/Stats/Stats.vue'
import { useEventStore } from "@/stores/EventStore";

const isInMaintenance = false;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/organisations',
        name: 'organisations',
        component: OrganisationsView
    },
    {
        path: '/session/resume',
        name: 'session_resume',
        component: SessionResumeView
    },
    {
        path: '/:organisationId/evenements',
        name: 'events',
        component: EventsView
    },
    {
        path: '/:eventId/sessions',
        name: 'event_sessions',
        component: EventSessionsView
    },
    {
        path: '/guichet/tickets',
        name: 'ticket',
        component: TicketsView
    },
    {
        path: '/buvette/products',
        name: 'buvette',
        component: ProductsView
    },
    {
        path: '/buvette/infos',
        name: 'buvette_infos',
        component: ProductInfosView
    },
    {
        path: '/:eventId/guichet/tickets-seats',
        name: 'ticket_seats',
        component: TicketsSeatsView
    },
    {
        path: '/:eventId/guichet/payment',
        name: 'payment',
        component: PaymentView
    },
    {
        path: '/:eventId/guichet/informations',
        name: 'infos',
        component: InfosView
    },
    {
        path: '/:eventId/guichet/summary/:orderId',
        name: 'summary',
        component: SummaryView
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView
    },
    {
        path: '/orders',
        name: 'orders',
        component: OrdersView
    },
    {
        path: '/orders/:orderId',
        name: 'order_details',
        component: OrderDetailsView
    },
    {
        path: '/stats',
        name: 'stats',
        component: StatsView
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {

    const userStore = useUserStore()
    const eventStore = useEventStore()

    if (isInMaintenance && to.name !== 'maintenance') {
        next({ name: 'maintenance' });
        return;
    }

    if (!isInMaintenance && to.name === 'maintenance') {
        next({ name: 'dashboard' });
        return;
    }

    if (to.name !== 'logout') await userStore.userGuard();


    if (userStore.me && !userStore.sellingSession && to.name != 'organisations' && to.name != 'events') {
        next({ name: 'organisations' });
    }

    // Resume session
    if (userStore.me && userStore.sellingSession && !userStore.sessionStarted && to.name != 'session_resume') {
        next({ name: 'session_resume' });
    }

    // Login if not logged.
    if (to.name != 'login' && !userStore.me) {
        next({ name: 'login' });
    }

    // To organisations.
    if (to.name == 'login' && userStore.me) {
        next({ name: 'organisations' });
    }

    // Event Sessions selection
    if (eventStore.currentEvent) {
        if (to.name != 'event_sessions' && !eventStore.currentSession && eventStore.currentEvent.dates.type == 'sessions') {
            next({ name: 'event_sessions', params: {eventId: eventStore.currentEvent.id} });
        }
    }

    next();
});

router.onError(async () => {
    return '/';
});

export default router
