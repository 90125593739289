<template>
    <div class="page-guichet page-guichet--infos">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Guichet" />
            <div class="content_inner">
                <div class="content_inner_inner">
                    <FormKit
                        id="orderInfosForm"
                        :actions="false"
                        type="form"
                        v-model="formData"
                        @submit-invalid="invalidSubmit"
                        @submit="handleSubmit">
                            <FormKitSchema
                                :library="library"
                                :schema="formKitSchema"
                            />
                    </FormKit>
                </div>
                <Cart />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useEventStore } from '@/stores/EventStore'
    import { useTicketTypeStore } from '@/stores/TicketTypeStore'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import { computed, markRaw, onMounted, reactive, ref } from 'vue'
    import { useRouter } from 'vue-router'
    import { toast } from 'vue3-toastify';
    import { useI18n } from 'vue-i18n';
    import { useTicketTypeFormStore } from '@/stores/TicketTypeFormStore';
    import { useCartStore } from '@/stores/CartStore';
    import { CartItem, CartTicket } from '@/interfaces/CartItem';
    import { FormField } from '@/interfaces/FormField';
    import IconSvg from '@/components/atoms/ASvg.vue'
    import { getValidationMessages } from '@formkit/validation'
    import { FormKitNode } from '@formkit/core';
    import { Addon } from '@/interfaces/Addon';
    import AddonComponent from '@/components/molecules/Addons.vue'
    import { payloadOrderInfosType } from '@/interfaces/Types.definition'
    import { useAppStore } from '@/stores/AppStore'
import { TicketTypeForm } from '@/interfaces/TicketTypeForm'

    const cartStore = useCartStore()
    const formStore = useTicketTypeFormStore()
    const eventStore = useEventStore()
    const ticketTypeFormStore = useTicketTypeFormStore()
    const ticketTypeStore = useTicketTypeStore()
    const router = useRouter()
    const { t } = useI18n();
    const isReady = ref(false)
    const library = markRaw({
        IconSvg: IconSvg,
        AddonComponent
    })

    const formData = ref([])
    const hasActiveAddons = ref(false)
    const hasTicketFields = ref(false)

    const skipOrderInfos = computed(() => {
        if (eventStore.currentEvent.ticketOffice) {
            return eventStore.currentEvent.ticketOffice.skipOrderInfo
        }
        return false
    })

    const skipTicketInfos = computed(() => {
        if (eventStore.currentEvent.ticketOffice) {
            return eventStore.currentEvent.ticketOffice.skipTicketInfo
        }
        return false
    })

    const skipAddonsInfos = computed(() => {
        if (eventStore.currentEvent.ticketOffice) {
            return eventStore.currentEvent.ticketOffice.skipAddonInfo
        }
        return false
    })

    let orderInfosSchema = [
        {
          $el: 'h3',
          attrs: {
            class: ['title', 'title--h3']
          },
          children: [t('infosPage.order_infos')]
        },
        {
            $el: 'div',
            attrs: {
                class: ['row', 'row--2cols', 'fields-ctn']
            },
            children: [
                {
                    $formkit: 'group',
                    name: 'orderInfo',
                    children: [
                        {
                            $formkit: 'text',
                            name: 'firstName',
                            label: t('form.tickets.firstname.label'),
                            validation: 'string|required|length:2',
                        },
                        {
                            $formkit: 'text',
                            name: 'lastName',
                            label: t('form.tickets.lastname.label'),
                            validation: 'string|required|length:2'
                        },
                        {
                            $formkit: 'email',
                            name: 'email',
                            'suffix-icon':'email',
                            label: t('form.tickets.email.label'),
                            validation: 'string|required|email',
                        },
                    ]
                },

            ]
        }
    ]

    if (skipOrderInfos.value === true) {
        orderInfosSchema = []
    }


    const formKitSchema = ref<any>(orderInfosSchema)

    onMounted(async () => {
        if (!cartStore.order) {
            toast.error('Problème avec la commande')
            router.push({name: 'ticket', params: { eventId: eventStore.currentEvent?.id }})
        }

        if (eventStore.currentEvent?.id) {
            await formStore.fetchForms()
            await ticketTypeStore.fetchAddons()
            setFieldsToTickets()
            setDynamicForm()
            isReady.value = true
        }

        if (!hasTicketFields.value && !hasActiveAddons.value && skipOrderInfos.value) {
            router.push({name: 'payment', params: { eventId: eventStore.currentEvent?.id }})
        }
    })

    /**
     * Formulaire incomplet
     * @param node
     */
    const invalidSubmit = (node: FormKitNode) => {
        toast.error('Veuillez compléter toutes les informations nécessaires')
    };

    /**
     * Transform the formdata object to a form data array ready for payload.
     * @param fields
     */
    const handleSubmit = async (fields: any) => {

        const formData: payloadOrderInfosType = {
            invoiceData: {},
            tickets: []
        }

        if ('orderInfo' in fields) {
            formData['invoiceData'] = fields.orderInfo
        }

        for (const [ticketKey, _ticketValue] of Object.entries(fields)) {
            const ticketValue: any = _ticketValue
            if (ticketKey.substring(0, 8) == 'ticket__') {
                const ticket = {
                    ticketTypeId: ticketValue.ticket_type_id,
                    form: [],
                    addons: [],
                    tempId: ticketValue.ticket_temp_id
                }
                for (const [formKey, _formValue] of Object.entries(ticketValue)) {
                    const formValue: any = _formValue
                    if (formKey.substring(0, 6) == 'form__') {
                        const form = {
                            id: `${formValue.id}`,
                            fields: []
                        }
                        for (const [fieldKey, fieldValue] of Object.entries(formValue)) {
                            if (fieldKey.substring(0, 7) == 'field__') {
                                console.log(fieldValue);

                                const fieldId = (fieldKey.split('__'))[1]
                                const field = {
                                    formFieldId: fieldId,
                                    values: fieldValue
                                }
                                form.fields.push(field)
                            }
                        }
                        ticket.form.push(form)
                    }
                }
                formData.tickets.push(ticket)
            }
        }

        const updateOrder = await cartStore.updateOrderInfos(formData)
        if (updateOrder) {
            cartStore.nextStep()
        }

    };

    /**
     * Ajoute les champs de formulaires à chaque ticket.
     */
    const setFieldsToTickets = () => {
        if (ticketTypeFormStore.forms) {
            cartStore.items.forEach((cartItem: CartItem) => {
                const forms: TicketTypeForm[] = ticketTypeFormStore.forms.filter((form: TicketTypeForm) => {
                    if (form.ticketTypes.restrict && !form.ticketTypes.ids.includes(cartItem.ticketType.id)) {
                        return false
                    }
                    return true
                })
                if (forms) {
                    cartItem.tickets.forEach((cartTicket: CartTicket) => {
                        cartTicket._app.forms = forms
                    })
                }
            })
        }
    }

    /**
     * Construit le shema formkit pour le formulaire d'infos.
     */
    const setDynamicForm = () => {
        let ticketIndex = 1
        cartStore.items.forEach((cartItem: CartItem, cartItemIndex: number) => {
            cartItem.tickets.forEach((cartTicket: CartTicket, cartTicketIndex: number) => {

                if (!skipTicketInfos.value || !skipAddonsInfos.value) {
                    let isEmpty = true
                    let ticketName = cartItem.ticketType.name
                    if (cartTicket.seat) {
                        ticketName += ` (${cartTicket.seat})`
                    }
                    const ticketFormSchema: any = {
                        $el: 'h3',
                        attrs: { class: ['title-ticket'] },
                        children: [
                            {
                                $el: 'div',
                                children: [
                                { $cmp: 'IconSvg', props: { name: 'ticket' } },
                                ticketName,
                                ]
                            },
                            { $el:'span', children: [`${ticketIndex}/${cartStore.itemsQty}`] }
                        ]
                    }

                    const fieldsContainer = {
                        $el: "div",
                        attrs: { class: ['fields-ctn', 'ticket-fields-ctn'] },
                        children: [
                            {
                                $formkit: 'group',
                                name: `ticket__${ticketIndex}`,
                                children: [{
                                    $el: "div",
                                    attrs: { class: ['row', 'row--2cols'] },
                                    children: [{}]
                                }]
                            }
                        ]
                    }

                    const hiddenField: any = {
                        $formkit: 'hidden',
                        name: 'ticket_temp_id',
                        value: cartTicket._app.tempId,
                    }

                    const ticketTypeIdField: any = {
                        $formkit: 'hidden',
                        name: 'ticket_type_id',
                        value: cartItem.ticketType.id,
                    }

                    fieldsContainer.children[0].children.push(hiddenField)
                    fieldsContainer.children[0].children.push(ticketTypeIdField)

                    if (cartTicket._app.forms) {
                        cartTicket._app.forms.forEach((form: TicketTypeForm, formIndex: number) => {

                            // FORM.
                            const formGroup = {
                                $formkit: 'group',
                                name: `form__${formIndex}`,
                                children: [{
                                    $formkit: 'hidden',
                                    name: 'id',
                                    value: form.id
                                }]
                            }

                            if (form.fields && !skipTicketInfos.value) {

                                form.fields.forEach((formField: FormField, fieldIndex: number) => {
                                    let formkitField: any = {
                                        $formkit: getFormkitType(formField),
                                        name: `field__${formField.id}`,
                                        options: formField.options,
                                        label: formField.label,
                                        placeholder: formField.label,
                                        help: formField.description,
                                        validation: getFormkitValidation(formField),
                                        "validation-visibility": "dirty",
                                    }

                                    if (formField.type == 'score') {
                                        formkitField = {
                                            ...formkitField,
                                            min: 1,
                                            max: 10
                                        }
                                    }
                                    formGroup.children.push(formkitField)
                                    hasTicketFields.value = true
                                    isEmpty = false
                                })
                            }
                            fieldsContainer.children[0].children[0].children.push(formGroup)
                        })
                    }

                    // Addons.
                    if (!skipAddonsInfos.value) {
                        const addonsGroup = {
                            $el: 'div',
                            attrs: { class: ['addon-ctn'] },
                            children: [{
                                $el: "h3",
                                attrs: { class: ['title-addon'] },
                                children: ['Options']
                            }]
                        }

                        if (ticketTypeStore.addons.length) {
                            const addons = ticketTypeStore.addons.filter((addon:Addon) => addon.ticketTypeIds.includes(cartItem.ticketType.id))
                            if (addons.length) {
                                const adonField: any = {
                                    $cmp: 'AddonComponent',
                                    props: {
                                        ticketTypeId: cartItem.ticketType.id,
                                        name: `${cartItem.ticketType.id}_${ticketIndex}`,
                                        cartTicket,
                                        addons,
                                    },
                                }
                                addonsGroup.children.push(adonField)
                                hasActiveAddons.value = true
                                isEmpty = false
                            }
                        }

                        if (addonsGroup.children.length > 1) {
                            fieldsContainer.children[0].children.push(addonsGroup)
                        }
                    }

                    if (isEmpty) {
                        fieldsContainer.attrs.class.push('is-empty')
                    }
                    formKitSchema.value.push(ticketFormSchema)
                    formKitSchema.value.push(fieldsContainer)


                    ticketIndex += 1
                }
            })
        })
    }

    /**
     * Generate formkit validation.
     *
     * @param formField
     */
    const getFormkitValidation = (formField: FormField) => {
        const validations: string[] = []
        if (formField.isRequired) {
            validations.push('required')
        }
        if (formField.constraints.minLength || formField.constraints.maxLength) {
            const minLength = (formField.constraints.minLength) ? formField.constraints.minLength : '0'
            const sizeLength = [minLength]
            if (formField.constraints.maxLength) {
                sizeLength.push(formField.constraints.maxLength)
            }
            validations.push(`length:${sizeLength.join(',')}`)
        }
        if (formField.constraints.min) {
            validations.push(`min:${formField.constraints.min}`)
        }
        if (formField.constraints.max) {
            validations.push(`max:${formField.constraints.max}`)
        }
        if (formField.type == 'link') {
            validations.push('url')
        }
        if (formField.constraints.startDate) {}
        if (formField.constraints.endDate) {}

        return validations.join('|')
    }

    /**
     * Map FormKit type and API field type.
     *
     * @param formField
     */
    const getFormkitType = (formField: FormField) => {
        const mappingObject = {
            textfield: 'text',
            textarea: 'textarea',
            email: 'email',
            phone: 'tel',
            address: 'text',
            link: 'url',
            date: 'date',
            number: 'number',
            checkbox: 'checkbox',
            radio: 'radio',
            score: 'range',
            select: 'select',
            time: 'time',
            upload: 'file'
        }
        if (formField.type in mappingObject) {
            return mappingObject[formField.type]
        }
        return 'text'
    }

</script>