<template>
    <div class="page-guichet page-guichet--ticket">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Guichet" />
            <div class="content_inner">
                <div class="content_inner_inner">
                    <div v-if="eventStore.isRoomPlacement">
                        <router-link :to="{name:'ticket', params:{eventId:eventStore.currentEvent.id}}">Retour</router-link>
                    </div>
                    <div id="chart"></div>
                </div>
                <Cart />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import { computed, onMounted, ref } from 'vue'
    import { useUserStore } from '@/stores/UserStore'
    import { useEventStore } from '@/stores/EventStore'
    import { AppPrice } from '@/tools/Price'
    const isSeatsIoReady = ref(false)
    const userStore = useUserStore()
    const eventStore = useEventStore()

    onMounted(async () => {
        const seatsIoScript = document.createElement("script");
        seatsIoScript.setAttribute("src", "https://cdn-eu.seatsio.net/chart.js")
        document.head.appendChild(seatsIoScript);
        const interval = setInterval(() => {
            if ('seatsio' in window) {
                isSeatsIoReady.value = true
                initSeatsIo(window.seatsio)
                clearInterval(interval);
            }
        })
    })
    const seatsIoeventId = computed(() => {
        if (eventStore.currentSession) {
            return eventStore.currentSession.id
        }
        if (eventStore.currentEvent) {
            return eventStore.currentEvent.id
        }
        return false
    })

    const initSeatsIo = (seatsio: any) => {
        new seatsio.SeatingChart({
            divId: 'chart',
            priceFormatter: (price: number) => {
                return new AppPrice(price).toString()
            },
            pricing: [
                {
                    category: 1,
                    ticketTypes: [
                        {
                            ticketType: 'TA',
                            price: 30,
                            label: 'Tarif adulte'
                        },
                        {
                            ticketType: 'TJ',
                            price: 10,
                            label: 'Tarif jeune'
                        }
                    ]
                }
            ],
            showFullScreenButton: true,
            workspaceKey: userStore.organisation?.seatsIoWorkspace,
            event: seatsIoeventId.value,
            onObjectSelected: (obj: any) => {
                console.log('Selected...', obj);

            }
        }).render();
    }
</script>