<template>
    <div class="form_input--checkbox form_input">
      <div class="field-ctn field-border-gradient">
        <input v-model="localValue" @change="onCheckBoxChange(value)" class="apple-switch" type="checkbox" :name="name" :id="id" :value="value" />
        <label :for="id">
            {{ label }}
        </label>
      </div>
    </div>
</template>

<script lang="ts" setup>
  import { ref, computed } from 'vue';

  const emit = defineEmits(['changed'])

  interface Props {
    isMultiple?: boolean,
    name: string,
    value: string,
    label: string,
    description: string | null,
    isChecked?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    isMultiple: false,
    isChecked: false,
    description: null,
  })

  const id = computed(() => {
    return `${props.name}_${props.value}`
  })

  const localValue = ref(props.isChecked)

  const onCheckBoxChange = (value:unknown) => {
    emit('changed', {
      value: props.value,
      checked: localValue.value
    });
  }


</script>
