<template>
    <div class="page-guichet page-guichet--ticket">
        <SidebarVue />
        <div class="content">
            <StatusBar :showQuota="true" />
            <div class="content_inner">


                <div class="content_inner_inner" v-if="ticketTypeStore.ticketsPerCategory">

                    <TicketTypeCategoryVue v-for="(category, indexCat) in ticketTypeStore.ticketsPerCategory" :key="indexCat" :category="category.category">
                        <template v-if="category.tickets.length">
                            <TicketTypeVue
                                v-for="(ticketType, indexTt) in category.tickets"
                                :key="indexTt"
                                :ticketType="ticketType"
                                :category="category.category"
                            />
                        </template>
                    </TicketTypeCategoryVue>
                </div>
                <Cart />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useTicketTypeStore } from '@/stores/TicketTypeStore'
    import TicketTypeVue from '@/components/molecules/CardTicketType.vue'
    import TicketTypeCategoryVue from '@/components/molecules/TicketTypeCategory.vue'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import { onMounted } from 'vue'
    import { useCartStore } from '@/stores/CartStore'
    import { useEventStore } from '@/stores/EventStore'
    import { useUserStore } from '@/stores/UserStore'

    const ticketTypeStore = useTicketTypeStore()
    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const userStore = useUserStore()

    onMounted(async () => {
        await cartStore.cancel()
        cartStore.isProduct = false
        const eventId = userStore.sellingSession?.eventId
        await eventStore.retrieveEvent(eventId)
        await ticketTypeStore.fetchAll()
    })
</script>