<template>
    <div class="page-sessions">
        <div class="container">
            <div class="inner">
                <h1 class="title title--h1 title--separator">{{ $t('auth.sessionsPage.title') }}</h1>
                <div v-if="filteredSessions.length" class="list-sessions">
                    <CardSession @clicked="selectSession" :session="session" v-for="session in filteredSessions" :key="session.id" />
                </div>
                <div v-else class="empty-sessions">
                    <h2 class="title-h2">
                        {{ $t('generic.noresult') }}
                    </h2>
                </div>
            </div>
            <SessionLogoutVue />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useEventStore } from '@/stores/EventStore'
    import CardSession from '@/components/molecules/CardSession.vue'
    import SessionLogoutVue from '@/components/atoms/SessionLogout.vue'
    import { useUserStore } from '@/stores/UserStore';
    import { toast } from 'vue3-toastify';
    import { EventSession } from '@/interfaces/EventSession';
    const eventStore = useEventStore()
    const route = useRoute()
    const router = useRouter()
    const userStore = useUserStore()
    const filterText = ref(null)
    const filterList = (val: any) => {
        filterText.value = val
    }

    onMounted(async () => {
        const id = String(route.params.eventId)
        await eventStore.fetchEventsSession(id)
    })

    const filteredSessions = computed(() => {
        if (!eventStore.sessions) {
            return []
        }
        return eventStore.sessions?.filter((el: EventSession) => {
            if (!filterText.value) {
                return true
            }
            let sampleRegEx = new RegExp(`${filterText.value}`, 'gmi')
            return sampleRegEx.test(el.name)
        })
    })

    const selectSession = async (session: EventSession) => {
        try {
            eventStore.currentSession = session
            if (eventStore.currentEvent) {
                router.push({name: 'ticket', params: { eventId: eventStore.currentEvent.id}});
            }
        } catch (error) {
            toast.error('Impossible de créer une session de vente')
        }
    }

</script>