import { defineStore, Store } from "pinia";
import { DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import { getAllItemsAction, getItemAction } from "./common/actions";
import { SellingSession, ISellingSessionStats } from "@/interfaces/SellingSession";
import { useUserStore as userStore } from "./UserStore";
import { useEventStore as eventStore } from "./EventStore";
import { toast } from "vue3-toastify";
import axios from "@/tools/Axios/axios";

export interface SellingSessionState {
    currentSellingSession: SellingSession | null,
    sellingSessions: SellingSession[],
    stats: ISellingSessionStats
}

export const useSellingSessionStore = defineStore("SellingSessionStore", {
    state: (): SellingSessionState => {
        return {
            currentSellingSession: null,
            sellingSessions: [],
            stats: null
        }
    },
    actions: {
        async fetch(sellingSessionId: string) {
            let params: GetParams = DefaultGetParams()
            params.select = ['authorId', 'status', 'createdAt', 'closedAt', 'updatedAt', 'eventId', 'sessionNumber', 'organisationId']
            params.populate = ['author']
            await getItemAction<SellingSession, Store<'SellingSessionStore'>>(`/ticket-offices/selling-sessions/${sellingSessionId}`, params, this, 'currentSellingSession')
        },
        async fetchAll(userRestricted: boolean = false) {
            let params: GetParams = DefaultGetParams()
            params.select = ['authorId', 'status', 'createdAt', 'closedAt', 'updatedAt', 'eventId', 'sessionNumber', 'organisationId']
            params.populate = ['author']
            params.filter = {
                eventId: eventStore().currentEvent?.id,
                status: 'opened,paused,closed'
            }
            if (userRestricted) {
                params.filter['authorId'] = userStore().me?.id
            }
            await getAllItemsAction<SellingSession, Store<'SellingSessionStore'>>(`/ticket-offices/selling-sessions/list`, params, this, 'sellingSessions')
        },
        async fetchStats(sellingSessionId: string = null) {
            let url = `/ticket-offices/selling-sessions/stats`
            try {
                const { data } = await axios.post(url, {
                    sellingSessionId,
                }, {params: { eventId: eventStore().currentEvent.id }})
                console.log(data);

                this.stats = data
                return true
            } catch (error) {
                toast.error("Une erreur est survenue")
                toast.error(error.message)
                return false
            }
        }
    }
})