<template>
    <div class="page-default page--order">
        <SidebarVue />
        <div class="content">
            <StatusBar
                @previousClicked="goToPrevious"
                :show-previous="true"
                :show-quota="false"
                :show-tpe="false"
                :show-menu="false"
                :title="title"
            />
            <div class="content_inner">
                <div class="infos_actions">
                    <div class="actions">
                        <Button :full-width="false" @click="printOrder" icon="printer">
                            Imprimer
                        </Button>

                        <Button :full-width="false" @click="vfm.open(sendOrderModalId)" icon="send">
                            <template v-if="orderStore.order.isProduct">
                                Envoyer le ticket
                            </template>
                            <template v-else>
                                Envoyer les billets
                            </template>
                        </Button>
                        <Button :full-width="false" v-if="orderStore.order?.totalPrice?.sellingPrice > 0" @click="vfm.open(sendInvoiceModalId)" icon="invoice">
                            Facture
                        </Button>
                    </div>
                    <div class="infos">

                        <MTable :rows="mainTableData" :labels="mainTableLabels" />
                    </div>
                </div>

                <div v-if="orderStore.order.isProduct" class="products">
                    <h3 class="title title--h3"><span>{{ orderStore.tickets.length }}</span> produit(s)</h3>
                    <MTable
                        :selectable="false"
                        :show-labels="true"
                        :rows="productsTableData"
                        :labels="productsTableLabels"
                    />
                </div>
                <div v-else class="tickets">
                    <h3 class="title title--h3"><span>{{ orderStore.tickets.length }}</span> billet(s)</h3>
                    <MTable
                        @clicked="printTicket"
                        :selectable="true"
                        :show-labels="true"
                        :rows="ticketsTableData"
                        :labels="ticketsTableLabels"
                    />
                </div>

            </div>

            <ModalConfirm :lockScroll="false" :modal-id="sendOrderModalId" title="Envoyer les billets" @confirm="() => confirmSendOrderModalId()">
                <FormKit
                    type="form"
                    id="sendOrderModalForm"
                    v-model="sendOrderData"
                    :actions="false"
                    @submit="handleOrderSubmit"
                    >
                    <FormKit
                        type="text"
                        name="mail"
                        label="Email"
                        placeholder="Adresse e-mail"
                        :value="orderStore.order.invoiceData.email"
                        validation="required|email"
                    />
                </FormKit>
            </ModalConfirm>

            <ModalConfirm :modal-id="sendInvoiceModalId" title="Envoyer une facture" @confirm="() => confirmSendInvoice()">
                <FormKit
                    type="form"
                    id="sendInvoiceModalForm"
                    v-model="sendInvoiceData"
                    :actions="false"
                    @submit="handleInvoiceSubmit"
                    >
                    <FormKit
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Adresse e-mail"
                        :value="orderStore.order.invoiceData.email"
                        validation="required|email"
                    />
                    <div class="row--2cols">
                        <FormKit
                            type="text"
                            name="firstName"
                            label="Prénom"
                            :value="orderStore.order.invoiceData.firstName"
                            validation=""
                        />
                        <FormKit
                            type="text"
                            name="lastName"
                            label="Nom"
                            :value="orderStore.order.invoiceData.lastName"
                            validation=""
                        />
                    </div>
                    <div class="row--2cols">
                        <FormKit
                            type="text"
                            name="company"
                            :value="orderStore.order.invoiceData.company"
                            label="Société"
                            validation="required"
                        />
                        <FormKit
                            type="text"
                            name="numTva"
                            label="Numéro de TVA"
                            :value="orderStore.order.invoiceData.numTva"
                            validation="required"
                        />
                    </div>
                    <FormKit
                        type="textarea"
                        name="address"
                        label="Adresse"
                        :value="orderStore.order.invoiceData?.address"
                        validation=""
                    />
                </FormKit>
            </ModalConfirm>

        </div>
    </div>
</template>
<script setup lang="ts">
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import Button from '@/components/atoms/Button.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import MTable from '@/components/molecules/Table.vue'
    import ModalConfirm from '@/components/molecules/modal/ModalConfirm.vue'

    import { useOrderStore } from '@/stores/OrderStore';
    import { onMounted, computed, ref, reactive } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { OrderOriginEnum, OrderStatusEnum, PaymentMethodEnum, PaymentMethodTypeEnum } from '@/interfaces/Order';
    import { useSellingSessionStore } from '@/stores/SellingSessionStore';
    import { AppPrice } from '@/tools/Price';
    import { useVfm } from 'vue-final-modal'
    import { submitForm } from '@formkit/vue'
    import { useI18n } from 'vue-i18n'

    const router = useRouter()
    const orderStore = useOrderStore()
    const sellingSessionStore = useSellingSessionStore()
    const route = useRoute()
    const vfm = useVfm()
    const { t } = useI18n();

    const sendOrderModalId = Symbol('sendOrderModalId')
    const sendOrderData = ref(null)

    const sendInvoiceModalId = Symbol('sendInvoiceModalId')
    const sendInvoiceData = ref(null)

    onMounted(async () => {
        const orderId = route.params.orderId.toString()
        await orderStore.fetchOrder(orderId)
        if (orderStore.order) {
            if (orderStore.order.ticketOfficeSessionId) {
                await sellingSessionStore.fetch(orderStore.order.ticketOfficeSessionId)
            }
            await orderStore.fetchTicketsByOrder(orderStore.order)
        }
    })

    const confirmSendInvoice = (async () => {
        submitForm('sendInvoiceModalForm')
    })

    const confirmSendOrderModalId = (() => {
        submitForm('sendOrderModalForm')
    })

    const title = computed(() => {
        if (orderStore.order) {
            return `Commande n°${orderStore.order.orderNumber}`
        }
        return ''
    })

    const handleInvoiceSubmit = (async () => {
        await orderStore.sendInvoice(orderStore.order, sendInvoiceData.value)
        vfm.close(sendInvoiceModalId)
    })

    const handleOrderSubmit = (async () => {
        await orderStore.sendTicketsToMail(orderStore.order, sendOrderData.value.mail)
        vfm.close(sendOrderModalId)
    })

    const goToPrevious = (() => {
        const isProduct = (orderStore.order?.isProduct) ? 1 : 0
        router.push({name: 'orders', query: {isProduct: isProduct}})
    })

    const printOrder = (() => {
        orderStore.printOrder(orderStore.order, orderStore.tickets)
    })

    const printTicket = ((el) => {
        const foundTicket = orderStore.tickets.find(ticket => ticket.id === el.$id)
        if (foundTicket) {
            orderStore.printTicket(orderStore.order, foundTicket)
        }
    })

    /**
     * @TODO : Refacto la partie date.
     */
     const formatDate = ((dateTime) => {
        let dateString = ''
        const date = new Date(dateTime)
        dateString += date.toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        dateString += ' à '
        dateString += date.toLocaleTimeString('fr', {
            hour: '2-digit',
            minute: '2-digit'
        })
        return dateString
    })

    const productsTableLabels = computed(() => {
        return [
            {
                label: "Produit",
                value: "product",
                align: "left"
            },
            {
                label: "Quantité",
                value: "quantity",
                align: "left"
            },
            {
                label: "Prix unitaire",
                value: "unit_price",
                align: "left"
            },
            {
                label: "Prix total",
                value: "total_price",
                align: "left"
            },
        ]
    })
    const productsTableData = computed(() => {
        const products = []
        if (orderStore.tickets) {
            orderStore.tickets.forEach(ticket => {
                const foundProduct = products.find(product => product.$id === ticket.ticketTypeId)
                if (!foundProduct) {
                    products.push({
                        $id: ticket.ticketTypeId,
                        product: ticket.name,
                        quantity: 1,
                        unit_price: (ticket.price) ? new AppPrice(ticket.price.sellingPrice).divide100() : '--',
                        total_price: (ticket.price) ? new AppPrice(ticket.price.sellingPrice).divide100() : '--'
                    })
                }
                else {
                    foundProduct.quantity += 1
                    const ticketPrice = (ticket.price) ? ticket.price.sellingPrice : 0
                    foundProduct.total_price = new AppPrice(Number(ticketPrice) * foundProduct.quantity).divide100()
                }
            })
        }
        return products
    })

    const ticketsTableLabels = computed(() => {
        return [
            {
                label: "Tarif",
                value: "ticketType",
                align: "left"
            },
            {
                label: "Nom",
                value: "name",
                align: "left"
            },
            {
                label: "N°billet",
                value: "number",
                align: "left"
            },
            {
                label: "Total billet",
                value: "price",
                align: "left"
            },
        ]
    })

    const ticketsTableData = computed(() => {
        if (orderStore.tickets) {
            return orderStore.tickets.map(ticket => {
                const infos = []
                const name = [ticket.name]
                const number = [ticket.ticketNumber]
                if (ticket.firstName) {
                    infos.push(ticket.firstName)
                }
                if (ticket.lastName) {
                    infos.push(ticket.lastName)
                }

                if (ticket.seat) {
                    number.push(`Place: ${ticket.seat}`)
                }
                if (ticket.addons) {
                    ticket.addons.forEach(addon => {
                        name.push(`${addon.name} (${new AppPrice(addon.price).divide100()})`)
                    })
                }

                return {
                    $id: ticket.id,
                    ticketType: name,
                    name: (infos.length) ? infos.join(' ') : '- Anonyme -',
                    number: number,
                    price: new AppPrice(ticket.price.sellingPrice).divide100()
                }
            })
        }
    })

    const mainTableLabels = computed(() => {
        return [
            {
                label: "Libellé",
                value: "label",
                align: "left"
            },
            {
                label: "Valeur",
                value: "value",
                align: "left"
            },
        ]
    })

    const mainTableData = computed(() => {
        if (orderStore.order) {
            const data = [
                {
                    label: "Opérateur",
                    value: (sellingSessionStore.currentSellingSession) ? `${sellingSessionStore.currentSellingSession.author.firstName} ${sellingSessionStore.currentSellingSession.author.lastName}` : '--',
                },
                {
                    label: "Date",
                    value: formatDate(orderStore.order.createdAt)
                },
                {
                    label: "Mode de paiement",
                    value: (orderStore.order.payment.method == PaymentMethodTypeEnum.custom) ? orderStore.order.payment.customMethod?.label : PaymentMethodEnum[orderStore.order.payment.method]
                },
                {
                    label: "Total commande",
                    value: (orderStore.order.totalPrice) ? new AppPrice(orderStore.order.totalPrice.sellingPrice).divide100() : '--'
                }
            ]
            if (orderStore.order.invoiceData.zone) {
                data.push({
                    label: t('form.tickets.zone.label'),
                    value: orderStore.order.invoiceData.zone
                })
            }
            if (orderStore.order.invoiceData.additionalInfos) {
                data.push({
                    label: t('form.tickets.infos.label'),
                    value: orderStore.order.invoiceData.additionalInfos
                })
            }
            return data
        }
    })
</script>