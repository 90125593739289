<template>
    <div class="form_input--checkbox form_input">
      <div class="field-ctn field-border-gradient">
          <input @change="handleInput($event)" class="apple-switch" type="checkbox" :id="context.id" :name="context.id" :value="localValue" />
          <label :for="context.id">
              {{ context.label }}
          </label>
          <div class="help">
            {{ context.help }}
            </div>
      </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from "vue";
    const props = defineProps({
        context: Object,
    })
    const localValue = ref(props.context.value || false)
    function handleInput(e) {
        localValue.value = e.target.checked
        props.context.node.input(localValue.value)
    }
</script>
