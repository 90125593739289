import { defineStore, Store } from "pinia";
import axios from "@/tools/Axios/axios";
import { IUser } from "@/interfaces/User";
import { Organisation } from "@/interfaces/Organisation";
import { toast } from 'vue3-toastify';
import { getAllItemsAction, getItemAction } from "./common/actions";
import { APIListResponse, DefaultGetParams, GetParams } from "@/interfaces/Types.definition";
import { SellingSession } from "@/interfaces/SellingSession";
import { Event } from "@/interfaces/Event";
import * as Sentry from "@sentry/browser";

export interface UserState {
  me: IUser | null;
  sellingSession: SellingSession | null;
  sessionStarted: boolean;
  accessToken: string | null;
  roles: string[] | null;
  organisation: Organisation | null;
  organisations: Organisation[] | null;
}

export const useUserStore = defineStore("UserStore", {
  state: (): UserState => {
    return {
      me: null,
      sellingSession: null,
      sessionStarted: false,
      accessToken: null,
      roles: null,
      organisation: null,
      organisations: null,
    };
  },
  actions: {
    async setOrganisation(organisation: Organisation) {
      const { data } = await axios.get('/auth/' + organisation.id);
      this.accessToken = data.token;
      this.roles = data.roles;
      await storageSet('access_token', data.token)
      this.organisation = organisation
      await this.getActiveSellingSession()
      Sentry.setContext('organisation', {
        id: organisation.id,
        name: organisation.name
      })
    },
    async logOut() {
      storageRemove('access_token')
      this.$reset()
      toast('Vous avez bien été déconnecté')
    },
    async getMe() {
        let params: GetParams = {
          populate: ['avatar']
        }
        const { data } = await axios.get("/users/profile/me", { params });
        this.me = data
        Sentry.setContext('user', {
          id: data.id,
          name: `${this.me?.firstName} ${this.me?.lastName}`,
          email: this.me?.email
        })
        await this.getActiveSellingSession()
        if (!this.sellingSession) {
            await this.getUserOrganisations()
        }
        else {
          if (!this.organisation) {
            this.fetchOrganisation(this.sellingSession.organisationId)
          }
        }
    },
    async createSellingSession(event: Event) {
      await axios.post("/ticket-offices/selling-sessions", {eventId: event.id})
      this.sessionStarted = true
      await this.getActiveSellingSession()
    },
    async closeSession(): Promise<boolean> {
      try {
        if (this.sellingSession?.id) {
          await axios.put(`/ticket-offices/selling-sessions/${this.sellingSession?.id}`, {
              status: 'closed'
          })
        }
        this.logOut()
        return true
      } catch (error) {
        Sentry.captureException(error)
        toast.error(error.message)
      }
      return false
    },
    async getActiveSellingSession() {
      let params: GetParams = DefaultGetParams()
        params.filter = {
          authorId: this.me?.id,
          status: 'opened,paused',
        }
        if (this.me) {
          const { data, status }: APIListResponse<SellingSession> = await axios.get('/ticket-offices/selling-sessions/list', {params});
          if (status == 200) {
            this.sellingSession = data.data[0]
          }
          else {
            this.sellingSession = null
          }
        }
    },
    async getAccessToken(email: string, password: string): Promise<boolean> {
      try {
        const { data } = await axios.post("/auth", {
          email,
          password,
        });
        this.accessToken = data.token;
        this.roles = data.roles;
        await storageSet('access_token', data.token)
        await this.getMe()
        return true
      } catch (error) {
        if (error.constructor.name == 'AxiosError') {
          if (error.response.status == 401) {
            toast.error('Identifiants invalides')
            return false
          }
          Sentry.captureException(error)
          return false
        }
        else {
          Sentry.captureException(error)
        }
        toast.error(error.message)
        return false
      }
    },

    async getUserOrganisations() {
        let params: GetParams = DefaultGetParams()
        params.select = ['name', 'id', 'imageId', 'type', 'seatsIoWorkspace', 'seatsIoWorkspaceSecret', 'commission']
        params.populate = ['image']
        await getAllItemsAction<Organisation, Store<'UserStore'>>('/users/organisations/list', params, this, 'organisations')
    },
    async fetchOrganisation(organisationId: string) {
      let params: GetParams = DefaultGetParams()
      params.select = ['name', 'id', 'imageId', 'type', 'seatsIoWorkspace', 'seatsIoWorkspaceSecret', 'commission']
      await getItemAction<Organisation, Store<'UserStore'>>(`/users/organisations/${organisationId}`, params, this, 'organisation')
      Sentry.setContext('organisation', {
        id: this.organisation?.id,
        name: this.organisation?.name
      })
    },
    async userGuard() {
        if (!this.me) {
            const accessToken = await storageGet('access_token');
            if (accessToken) {
                this.accessToken = accessToken
                try {
                    await this.getMe();
                    return true;
                } catch (e) {
                    this.accessToken = null
                    await storageRemove('access_token');
                    toast.error('Connexion impossible');
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
  },
});

export const storageSet = async (key: string, value: any) => {
  localStorage.setItem(key, value)
};

export const storageGet = async (key: string) => {
    return localStorage.getItem(key)
};

export const storageRemove = async (key: string) => {
    localStorage.removeItem(key)
};
