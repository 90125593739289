<template>
    <CheckboxVue v-for="(addon, index) in addons" :key="index"
        @changed="addOnChanged"
        :name="name"
        :value="addon.id"
        :label="getAddonName(addon)"
        :description="addon.description"
    />
</template>
<script setup lang="ts">
    import CheckboxVue from '@/components/atoms/forms/Checkbox.vue';
    import { Addon } from '@/interfaces/Addon';
    import { CartTicket } from '@/interfaces/CartItem';
    import { AppPrice } from '@/tools/Price';
    import { toRefs } from 'vue';
    import { isNumber } from 'lodash';

    const emit = defineEmits(['changed'])

    interface Props {
        ticketTypeId: string,
        addons: Addon[],
        cartTicket: CartTicket,
        name: string
    }

    const props = withDefaults(defineProps<Props>(), {
        addons: null,
    })

    const { cartTicket } = toRefs(props)

    const addOnChanged = (val: {value: string, checked: boolean}) => {
        if (cartTicket.value._app.addons) {
            const selectedAddon = props.addons.find((addon: Addon) => addon.id == val.value)
            if (val.checked && selectedAddon) {
                cartTicket.value._app.addons?.push(selectedAddon);
            }
            if (!val.checked && selectedAddon) {
                const currentIndex = cartTicket.value._app.addons?.findIndex((addon: Addon) => (addon && addon.id === val.value))
                if (isNumber(currentIndex) && currentIndex >= 0) {
                    cartTicket.value._app.addons?.splice(currentIndex, 1)
                }
            }
        }
    }

    const getAddonName = (addon: Addon) => {
        const price = new AppPrice(addon.price).divide100().toString()
        return `${addon.name} (${price})`
    }

</script>