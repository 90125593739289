<template>
    <div class="page-guichet page-guichet--payment">
        <SidebarVue />
        <div class="content">
            <StatusBar :title="statusTitle" :isProduct="cartStore.order.isProduct" :showQuota="!cartStore.order.isProduct" />
            <div class="content_inner">
                <div class="content_inner_inner">
                    <div class="payment_methods">
                        <PaymentMethodVue
                            v-for="(paymentMethod, index) in paymentMethods"
                            :key="index"
                            :type="paymentMethod"
                        />
                    </div>
                    <div class="methods_extra" v-if="extraPaymentMethods && extraPaymentMethods.length">
                        <h3 class="title title--h3">
                            {{ $t('paymentPage.otherPayments') }}
                        </h3>
                        <div class="payment_methods payment_methods--extra">
                            <PaymentMethodVue
                                v-for="(extraMethod, index) in extraPaymentMethods"
                                :key="index"
                                :isDefault="false"
                                type="custom"
                                :subType="extraMethod.slug"
                                :label="extraMethod.label"
                            />
                        </div>
                    </div>
                </div>
                <Cart />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useEventStore } from '@/stores/EventStore'
    import PaymentMethodVue from '@/components/molecules/PaymentMethod.vue'
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import Cart from '@/components/organisms/Cart.vue'
    import { computed, onMounted } from 'vue'
    import { IPaymentTransaction } from '@/interfaces/PaymentTransaction'
    import { useCartStore } from '@/stores/CartStore'
    const eventStore = useEventStore()
    const cartStore = useCartStore()

    onMounted(async () => {
        if (cartStore.totalCart.amount === 0) {
            cartStore.paymentMethod = { method: 'free', customMethod: null }
            const isPaid = await cartStore.payOrder()
            if (isPaid) {
                cartStore.nextStep()
            }
        }
    })

    const paymentMethods = computed(() => {
        if (eventStore.currentEvent.ticketOffice) {
            return eventStore.currentEvent.ticketOffice.paymentMethods
        }
        else {
            return [
                'creditCardExternal',
                'cash',
                'check'
            ]
        }
    })

    const isProduct = computed(() => {
        return cartStore.order.isProduct
    })

    const extraPaymentMethods = computed(() => {
        if (eventStore.currentEvent.ticketOffice) {
            return eventStore.currentEvent.ticketOffice.extraPaymentMethods
        }
        return false
    })

    const statusTitle = computed(() => {
        if (eventStore.currentEvent) {
            return eventStore.currentEvent.name
        }
        return ''
    })


</script>