<template>
    <div class="page-login">
        <div class="left-part">
            <div class="inner">
                <IconSvg class="logo" :name="'logo-white'" />
            </div>
        </div>
        <div class="right-part">
            <div class="inner">
                <div class="head">
                    <h1 class="title title--h1">
                        {{ $t('auth.loginPage.title') }}
                    </h1>
                    <p class="message">
                        {{ $t('auth.loginPage.headMessage') }}
                    </p>
                </div>

                <FormKit
                    id="loginForm"
                    :actions="false"
                    class="form"
                    type="form"
                    v-model="formData"
                    @submit-invalid="invalidSubmit"
                    @submit="onSubmit">

                    <FormKit
                        type="email"
                        name="email"
                        :label="$t('auth.loginPage.form.email.label')"
                        :placeholder="$t('auth.loginPage.form.email.placeholder')"
                        validation="required|email"
                    />
                    <FormKit
                        type="password"
                        name="password"
                        suffix-icon="eyeClosed"
                        :label="$t('auth.loginPage.form.password.label')"
                        :placeholder="$t('auth.loginPage.form.password.placeholder')"
                        validation="required|length:8"
                        @suffix-icon-click="handleIconClick"
                    />

                    <Button @click="submitForm('loginForm')" icon="lock" size="big" :loading="isLoading">
                        {{ $t('auth.loginPage.form.submit') }}
                    </Button>

                </FormKit>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import IconSvg from '@/components/atoms/ASvg.vue'
    import Button from '@/components/atoms/Button.vue'
    import { submitForm } from '@formkit/core'
    import { useI18n } from 'vue-i18n';
    import { toast } from 'vue3-toastify';
    import { ref } from 'vue';
    import { useUserStore } from '@/stores/UserStore'
    import { useRouter } from 'vue-router';
    const { t } = useI18n();
    const router = useRouter()
    const userStore = useUserStore()
    const isLoading = ref(false)
    const formData = ref(null)

    const handleIconClick = (node, e) => {
        node.props.suffixIcon = node.props.suffixIcon === 'eye' ? 'eyeClosed' : 'eye'
        node.props.type = node.props.type === 'password' ? 'text' : 'password'
    }

    function invalidSubmit() {
        toast.error('Le formulaire contient des erreurs')
    }

    async function onSubmit(values: any) {
        isLoading.value = true
        const result = await userStore.getAccessToken(values.email, values.password)
        if (result) {
            toast(t('toast.welcome', {'name': userStore.me?.firstName}))
            router.push({name: 'organisations'});
        }
        else {
            isLoading.value = false
        }
    }

</script>