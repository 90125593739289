<template>
    <div @click="appStore.cartIsOpen = false" class="cart_overlay" :class="{isVisible: appStore.cartIsOpen}"></div>
    <div class="cart" :class="{'is-empty': cartStore.isEmpty, 'isOpen': appStore.cartIsOpen}">
        <div class="toggle_cart" v-if="appStore.cartIsOpen" @click="appStore.cartIsOpen = !appStore.cartIsOpen"></div>
        <div class="inner">
            <div class="top">
                <div v-if="cartStore.isEmpty" class="empty-cart">
                    <template v-if="isProduct">
                        <IconSvg class="icon" :name="'glass'" />
                        <div class="message">{{ $t('cart.empty_product_message') }}</div>
                    </template>
                    <template v-else>
                        <IconSvg class="icon" :name="'ticket'" />
                        <div class="message">{{ $t('cart.empty_message') }}</div>
                    </template>
                </div>
                <template v-else>
                    <div class="ticket-list">
                        <CartItem v-for="(item, index) in cartStore.items" :key="index" :deletable="(route.name == 'ticket' || route.name == 'buvette')" :item="item" />
                    </div>
                </template>
            </div>
            <div class="bottom">
                <div class="fees" :class="{updating: isUpdating}" v-if="cartStore.totalFees && cartStore.totalFees.amount">
                    <div class="label">
                        {{ $t('cart.fees') }}
                    </div>
                    <div class="price">
                        {{ cartStore.totalFees }}
                    </div>
                </div>
                <div class="total" :class="{updating: isUpdating}" v-if="!cartStore.isEmpty">
                    <div class="label">
                        {{ $t('cart.total') }}
                    </div>
                    <div class="price">
                        {{ cartStore.totalCartWithFees }}
                    </div>
                </div>

                <div class="left-to-pay" :class="{valid: cartStore.leftToPay.amount == 0}" v-if="route.name == 'payment'">
                    <div class="label">
                        {{ $t('cart.leftToPay') }}
                    </div>
                    <div class="price">
                        {{ cartStore.leftToPay }}
                    </div>
                </div>
                <div class="ctas">
                    <Button @click="nextStep()" class="add-cart" icon="cart" :disabled="btnDisabled" size="big">
                        {{ $t('cart.order') }}
                    </Button>
                    <Button class="cancel" :disabled="btnDisabled" variant="secondary" @click="cancelOrder()">
                        {{ $t('cart.cancel') }}
                    </Button>
                </div>
            </div>
            <div class="mobile_part">
                <div @click="appStore.cartIsOpen = !appStore.cartIsOpen" class="infos">
                    <div class="items">
                        <span>{{ cartStore.itemsQty }}</span>
                    </div>
                    <div class="price">
                        {{ cartStore.totalCart }}
                    </div>
                </div>
                <Button @click="nextStep()" class="add-cart" :disabled="btnDisabled" :full-width="false">
                    {{ $t('cart.order_small') }}
                </Button>
            </div>
        </div>
    </div>

    <ModalConfirm :modal-id="confirmCancelModalId" title="Annuler la commande" @confirm="() => confirmCancelOrder()">
      <p>Etes-vous sur de vouloir annuler cette commande ?</p>
    </ModalConfirm>

</template>

<script setup lang="ts">
    import IconSvg from '@/components/atoms/ASvg.vue'
    import CartItem from '@/components/molecules/CartItem.vue'
    import ModalConfirm from '@/components/molecules/modal/ModalConfirm.vue'
    import { useCartStore } from "@/stores/CartStore"
    import Button from '@/components/atoms/Button.vue'
    import { computed, ref } from 'vue'
    import { useEventStore } from '@/stores/EventStore'
    import { useRoute, useRouter } from 'vue-router'
    import { useI18n } from 'vue-i18n'
    import { useVfm } from 'vue-final-modal'
    import { useAppStore } from '@/stores/AppStore'
    import { submitForm } from '@formkit/core'
    const router = useRouter()
    const route = useRoute()
    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const appStore = useAppStore()
    const { t } = useI18n();
    const isUpdating = ref(false)
    const vfm = useVfm()

    const confirmCancelModalId = Symbol('confirmCancelModalId')

    const buttonLabel = computed(() => {
        return t('cart.order_small')
    })

    const props = defineProps({
        isProduct: {
            type: Boolean,
            required: false,
            default: false
        },
    });

    const confirmCancelOrder = async () => {
        await cartStore.cancel()
        appStore.cartIsOpen = false
        vfm.close(confirmCancelModalId)
        if (props.isProduct) {
            router.push({name: 'buvette'})
        }
        else {
            router.push({name: 'ticket', params: { eventId: eventStore.currentEvent?.id }})
        }
    }

    const cancelOrder = async () => {
        vfm.open(confirmCancelModalId)
    }

    const nextStep = async () => {
        switch (route.name) {
            case 'ticket':
                if (eventStore.isRoomPlacement) {
                    appStore.isLoading = true
                    const isValid = await cartStore.holdAvailablePlaces()
                    appStore.isLoading = false
                    if (!isValid) {
                        await cartStore.cancel()
                        router.push({name: 'ticket_seats', params: { eventId: eventStore.currentEvent?.id }})
                        break;
                    }
                }
                if (await cartStore.generateDraftOrder()) {
                    cartStore.nextStep()
                }
                break;
            case 'buvette':
                if (await cartStore.generateDraftOrder(true)) {
                    cartStore.nextStep()
                }
                break;
            case 'ticket_seats':
                if (await cartStore.generateDraftOrder()) {
                    cartStore.nextStep()
                }
                break;
            case 'infos':
                submitForm('orderInfosForm')
                break;
            case 'buvette_infos':
                submitForm('buvetteInfosForm')
                break;
            case 'payment':
                const isPaid = await cartStore.payOrder()
                if (isPaid) {
                    cartStore.nextStep()
                }
                break;
        }
        appStore.cartIsOpen = false
    }

    const btnDisabled = computed((): boolean => {
        let disabled = false

        if (cartStore.isEmpty) {
            disabled = true
        }
        switch (route.name) {
            case 'payment':
                if (!cartStore.paymentMethod) {
                    disabled = true
                }
                break;
        }
        return disabled
    })

    let oldTotal = 0
    cartStore.$subscribe((mutation, state) => {
        if (oldTotal != cartStore.totalCart.amount) {
            oldTotal = cartStore.totalCart.amount
            isUpdating.value = true
            setTimeout(() => {
               isUpdating.value = false
            }, 150);
        }
    })

</script>