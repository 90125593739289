<template>
    <div class="card-ctn" :class="{disabled: isDisabled}">
        <div @click="cardClicked()" :class="classes">
            <h3 class="card--title">{{ refLabel }}</h3>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { useVfm } from "vue-final-modal"
    import { inject, ref, toRef, watch } from "vue"
    import { useCartStore } from "@/stores/CartStore"
    import { computed } from "vue"
    import { IPaymentTransaction } from "@/interfaces/PaymentTransaction"
    import { AppPrice } from "@/tools/Price"
    import { toast } from "vue3-toastify"
    import { useTpeStore } from "@/stores/TpeStore"
    import { useAppStore } from "@/stores/AppStore"
    import { useRouter } from "vue-router"
    import { useEventStore } from "@/stores/EventStore"
    import { PaymentMethodEnum } from "@/interfaces/Order"
    import emitter from '@/tools/EventBus/EventBus';

    const cartStore = useCartStore()
    const tpeStore = useTpeStore()
    const appStore = useAppStore()
    const amountField = ref<number>(null)
    const leftToPay = ref<AppPrice>(null)
    const amountToReturn = ref<AppPrice>(null)
    const onError = ref<boolean>(false)
    const emit = defineEmits(['submitted', 'cancelled'])
    const router = useRouter()
    const eventStore = useEventStore()

    const props = defineProps({
        type: {type: String, required: true},
        subType: {type: String, required: false, default: null},
        isDefault: {type: Boolean, required: false, default: true},
        label: {type: String, required: false, default: ''}
    })

    emitter.on('button-clicked', (project) => {
        console.log("dazdzadz");
    })

    const refLabel = ref(props.label)
    if (props.isDefault === true) {
        refLabel.value = PaymentMethodEnum[props.type] || props.type
    }

    const isDisabled = computed(() => {
        if (isStripeTerminalMethod.value) {
            return (tpeStore.reader == null)
        }
        return false
    })

    const isStripeTerminalMethod = computed(() => {
        return (props.type == 'creditCardTerminal')
    })

    const cardClicked = async () => {
        if (!isDisabled.value) {
            const price = cartStore.totalCart.amount
            if (price > 0) {
                leftToPay.value = new AppPrice(price)
            }
            else {
                leftToPay.value = new AppPrice(0)
            }
            amountField.value = null
            await submitPayment()
        }
        else {
            if (isStripeTerminalMethod.value) {
                toast('Vous devez sélectionner votre terminal de paiement dans le menu "Réglages"')
            }
            onError.value = true
            setTimeout(() => onError.value = false , 1000);
        }
    }

    const classes = computed(() => {
        const classes = ['card', 'card__payment']

        if (cartStore.paymentMethod && cartStore.paymentMethod.method == props.type && cartStore.paymentMethod.customMethod == props.subType) {
            classes.push('active')
        }
        if (isDisabled.value) {
            classes.push('disabled')
        }
        if (onError.value) {
            classes.push('on-error')
        }
        return classes
    })

    const submitPayment = async () => {

        if (cartStore.isOrderExpired) {
            toast.error('La commande a expirée')
            await cartStore.cancel()
            router.push({name: 'ticket', params: { eventId: eventStore.currentEvent?.id }})
            return false
        }

        cartStore.paymentMethod = {
            method: props.type,
            customMethod: props.subType
        }

        emit('submitted', cartStore.paymentMethod);
    }

</script>