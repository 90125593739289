import fr from '@/i18n/langs/fr';
import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
    locale: 'fr',
    globalInjection: true,
    legacy: false,
    fallbackLocale: 'fr',
    messages: { fr }
})
