<template>
    <div class="page-guichet">
        <SidebarVue />
        <div class="content">
            <StatusBar title="Réglages" :showQuota="false" :showMenu="false" :showTpe="false" />
            <div class="content_inner">
                <div v-if="isTpeVisible">
                    <h3>Liste des Terminaux de paiement.</h3>
                    <div @click="readerClicked(reader)" class="card" :class="readerClasses(reader)" v-for="(reader, index) in tpeStore.readers" :key="index">
                        <h3>{{ reader.label }}</h3>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import SidebarVue from '@/components/organisms/Sidebar.vue'
    import StatusBar from '@/components/organisms/StatusBar.vue'
    import { useAppStore } from '@/stores/AppStore';
    import { useTpeStore } from '@/stores/TpeStore';
    import { onMounted, computed } from 'vue';
    import { useEventStore } from "@/stores/EventStore"

    const eventStore = useEventStore()
    const tpeStore = useTpeStore()
    const appStore = useAppStore()

    onMounted(async () => {

        if (isTpeVisible.value) {
            appStore.isLoading = true
            tpeStore.initTerminal()
            await tpeStore.discoverReaders()
            appStore.isLoading = false
        }

    })

    const isTpeVisible = computed(() => {
        return (eventStore.currentEvent.ticketOffice.paymentMethods.includes('creditCardTerminal'))
    })

    const readerClasses = (reader) => {
        const classes = {}
        if (tpeStore.reader) {
            if (tpeStore.reader.id == reader.id) {
                classes['active'] = true
            }
        }
        if (reader.status == 'offline') {
            classes['disabled'] = true
        }
        return classes
    }

    const readerClicked = (async (reader) => {
        if (tpeStore.reader) {
            await tpeStore.disconnectReader(reader)

        }
        else {
            appStore.isLoading = true
            await tpeStore.connectReader(reader)
            appStore.isLoading = false
        }
    })

</script>