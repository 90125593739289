import { PriceType } from "@/interfaces/Types.definition";
import { useI18n } from "vue-i18n";

export class AppPrice implements PriceType {

    _amount: number

    _formatted: string

    _currency: string

    _locale: string

    _zeroIsFree: boolean

    constructor(amount: number, currency: string = 'EUR', locale: string = 'fr-FR') {
        this._amount = amount
        this._formatted = ''
        this._currency = currency
        this._locale = locale
        this._zeroIsFree = false
        this.setFormatted()
    }

    public divide100() {
        this._amount = this._amount / 100
        this.setFormatted()
        return this
    }

    protected setFormatted(): void {

        if (this._amount <= 0 && this._zeroIsFree) {
            this._formatted = 'Gratuit'
        }
        else {
            this._formatted = new Intl.NumberFormat(this._locale, {
                style: 'currency',
                currency: this._currency
            }).format(this._amount)
        }
    }

    public toString():string {
        return this.formatted
    }

    get amount(): number { return this._amount}

    get formatted(): string { return this._formatted}

    get currency(): string { return this._currency}
}